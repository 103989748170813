import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { requestDeleteEditAdminAction } from '../../../../store/actions/edit-admin';

import UserListItem from '../../../user/list/item/item';
import UserListItemField from '../../../user/list/item/field/field';

class AdminEditListItem extends React.Component {
	constructor(props) {
		super(props);

		this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
	}

	onDeleteConfirm() {
		const { deleteAdmin, admin } = this.props;
		deleteAdmin(admin);
	}

	render() {
		const { admin, ...props } = this.props;

		return (
			<UserListItem
				confirmDeleteMessage={
					<>
						Vous êtes sur le point de <b>supprimer un administrateur</b>.
						Cette action est <b>irréversible</b>.<br />
						<br />
						Voulez-vous continuer?
					</>
				}
				editLink={`/admin/edit/${admin.id}`}
				onDeleteConfirm={this.onDeleteConfirm}
				{...props}
			>
				<div className="info">
					<UserListItemField label="Prénom" value={admin.firstName} />
					<UserListItemField label="Nom" value={admin.lastName} />
					<UserListItemField label="Téléphone" value={admin.phone} />
					<UserListItemField label="Email" value={admin.email} />
				</div>
			</UserListItem>
		);
	}
}

AdminEditListItem.propTypes = {
	admin: PropTypes.shape({ id: PropTypes.number }).isRequired,
	className: PropTypes.string,
	deleteAdmin: PropTypes.func.isRequired,
};

AdminEditListItem.defaultProps = {
	className: '',
};

const mapDispatchToProps = dispatch => ({
	deleteAdmin: admin => dispatch(requestDeleteEditAdminAction(admin)),
});

const AdminEditListItemContainer = connect(
	null,
	mapDispatchToProps,
)(AdminEditListItem);

export default AdminEditListItemContainer;
