"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var decoding = function decoding(str) {
  // str <=> username/temporaryPassword/name
  // username = sub
  var s = atob(decodeURI(str));
  return s.split('/').map(function (p) {
    return decodeURIComponent(p);
  });
};

var _default = decoding;
exports.default = _default;