"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateProgrammeStatus = exports.putCurrentProgramme = exports.getCurrentProgrammeToEdit = exports.deleteProgramme = exports.updateFiles = exports.deleteLotProgramme = exports.createLotProgramme = exports.updateLotProgramme = exports.updateEditProgrammeAction = exports.deleteProgrammeAction = exports.createProgramme = exports.EDIT_PROGRAMME_FILES_UPDATE_ACTION = exports.EDIT_PROGRAMME_LOT_DELETE_ACTION = exports.EDIT_PROGRAMME_LOT_CREATE_ACTION = exports.EDIT_PROGRAMME_LOT_UPDATE_ACTION = exports.EDIT_PROGRAMME_DELETE_ACTION = exports.EDIT_PROGRAMME_UPDATE_ACTION = exports.EDIT_PROGRAMME_CREATE_ACTION = exports.EDIT_PROGRAMME_SAVE_PREVIOUS_PROGRAMME = void 0;

var _programme = require("@cecaz-immo/app-client/dist/store/actions/programme");

var _session = require("../../lib/session");

var _uploadFile = require("../../lib/upload-file");

var _status = require("../../lib/status");

var REACT_APP_API_URL = process.env.REACT_APP_API_URL;
var EDIT_PROGRAMME_SAVE_PREVIOUS_PROGRAMME = 'EDIT_PROGRAMME_SAVE_PREVIOUS_PROGRAMME';
exports.EDIT_PROGRAMME_SAVE_PREVIOUS_PROGRAMME = EDIT_PROGRAMME_SAVE_PREVIOUS_PROGRAMME;
var EDIT_PROGRAMME_CREATE_ACTION = 'EDIT_PROGRAMME_CREATE_ACTION';
exports.EDIT_PROGRAMME_CREATE_ACTION = EDIT_PROGRAMME_CREATE_ACTION;
var EDIT_PROGRAMME_UPDATE_ACTION = 'EDIT_PROGRAMME_UPDATE_ACTION';
exports.EDIT_PROGRAMME_UPDATE_ACTION = EDIT_PROGRAMME_UPDATE_ACTION;
var EDIT_PROGRAMME_DELETE_ACTION = 'EDIT_PROGRAMME_DELETE_ACTION';
exports.EDIT_PROGRAMME_DELETE_ACTION = EDIT_PROGRAMME_DELETE_ACTION;
var EDIT_PROGRAMME_LOT_UPDATE_ACTION = 'EDIT_PROGRAMME_LOT_UPDATE_ACTION';
exports.EDIT_PROGRAMME_LOT_UPDATE_ACTION = EDIT_PROGRAMME_LOT_UPDATE_ACTION;
var EDIT_PROGRAMME_LOT_CREATE_ACTION = 'EDIT_PROGRAMME_LOT_CREATE_ACTION';
exports.EDIT_PROGRAMME_LOT_CREATE_ACTION = EDIT_PROGRAMME_LOT_CREATE_ACTION;
var EDIT_PROGRAMME_LOT_DELETE_ACTION = 'EDIT_PROGRAMME_LOT_DELETE_ACTION';
exports.EDIT_PROGRAMME_LOT_DELETE_ACTION = EDIT_PROGRAMME_LOT_DELETE_ACTION;
var EDIT_PROGRAMME_FILES_UPDATE_ACTION = 'EDIT_PROGRAMME_FILES_UPDATE_ACTION';
exports.EDIT_PROGRAMME_FILES_UPDATE_ACTION = EDIT_PROGRAMME_FILES_UPDATE_ACTION;

var createProgramme = function createProgramme() {
  return {
    type: EDIT_PROGRAMME_CREATE_ACTION
  };
};

exports.createProgramme = createProgramme;

var deleteProgrammeAction = function deleteProgrammeAction(programme) {
  return {
    type: EDIT_PROGRAMME_DELETE_ACTION,
    programme: programme
  };
};

exports.deleteProgrammeAction = deleteProgrammeAction;

var updateEditProgrammeAction = function updateEditProgrammeAction(programme) {
  return {
    type: EDIT_PROGRAMME_UPDATE_ACTION,
    programme: programme
  };
};

exports.updateEditProgrammeAction = updateEditProgrammeAction;

var updateLotProgramme = function updateLotProgramme(index, lot) {
  return {
    type: EDIT_PROGRAMME_LOT_UPDATE_ACTION,
    index: index,
    lot: lot
  };
};

exports.updateLotProgramme = updateLotProgramme;

var createLotProgramme = function createLotProgramme(lot) {
  return {
    type: EDIT_PROGRAMME_LOT_CREATE_ACTION,
    lot: lot
  };
};

exports.createLotProgramme = createLotProgramme;

var deleteLotProgramme = function deleteLotProgramme(index) {
  return {
    type: EDIT_PROGRAMME_LOT_DELETE_ACTION,
    index: index
  };
};

exports.deleteLotProgramme = deleteLotProgramme;

var updateFiles = function updateFiles(files) {
  return {
    type: EDIT_PROGRAMME_FILES_UPDATE_ACTION,
    files: files
  };
};

exports.updateFiles = updateFiles;

var transformFilesToFilesDescriptions = function transformFilesToFilesDescriptions(files) {
  var results = [];

  if (!files || !files.length) {
    return results;
  }

  files.forEach(function (f) {
    if (f.id) {
      results.push(f);
      return;
    }

    var newFile = {
      order: f.order,
      type: f.type
    };
    results.push(newFile);
  });
  return results;
};

var deleteProgramme = function deleteProgramme(programme) {
  return function (dispatch) {
    return (0, _session.fetchWithSession)("".concat(REACT_APP_API_URL, "/programme/").concat(programme.uuid, "/status"), {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify({
        status: _status.FINISHED
      })
    }).then(function () {
      dispatch(deleteProgrammeAction(programme));
    }).catch(function (e) {
      return console.log('error delete programme ', e);
    });
  };
};

exports.deleteProgramme = deleteProgramme;

var getCurrentProgrammeToEdit = function getCurrentProgrammeToEdit(uuid) {
  return function (dispatch) {
    return (0, _session.fetchWithSession)("".concat(REACT_APP_API_URL, "/programme/").concat(uuid, "/latest"), {
      headers: {
        'Cache-Control': 'no-cache'
      }
    }).then(function (resp) {
      return resp.json();
    }).then(function (result) {
      (0, _programme.transformProgramme)(result);
      dispatch(updateEditProgrammeAction(result));
    });
  };
};

exports.getCurrentProgrammeToEdit = getCurrentProgrammeToEdit;

var putCurrentProgramme = function putCurrentProgramme() {
  return function (dispatch, getState) {
    var programme = getState().editProgramme;
    var request = '';
    var method = '';

    if (!programme.uuid) {
      request = "".concat(REACT_APP_API_URL, "/programme");
      method = 'POST';
    } else {
      request = "".concat(REACT_APP_API_URL, "/programme/").concat(programme.uuid);
      method = 'PUT';
    }

    var files = programme.files;
    programme.files = transformFilesToFilesDescriptions(files);
    programme.floors = Number.parseInt(programme.floors, 10);
    return (0, _session.fetchWithSession)(request, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: method,
      body: JSON.stringify(programme)
    }).then(function (resp) {
      if (!resp.ok) {
        return Promise.reject(resp.json());
      }

      return resp;
    }).then(function (resp) {
      return resp.json();
    }).then(function (resp) {
      if (!files || !files.length) {
        return resp;
      }

      var promises = []; //Send new files in S3

      files.forEach(function (file, index) {
        if (!file.id) {
          promises.push((0, _uploadFile.sendFile)(resp.files[index].uploadUrl, file.blob));
        }
      });
      return Promise.all(promises).then(function () {
        return resp;
      });
    }).catch(function (e) {
      return console.error('error update programme ', e);
    });
  };
};

exports.putCurrentProgramme = putCurrentProgramme;

var updateProgrammeStatus = function updateProgrammeStatus(status, commentary) {
  return function (dispatch, getState) {
    var programme = getState().editProgramme;
    return (0, _session.fetchWithSession)("".concat(REACT_APP_API_URL, "/programme/").concat(programme.uuid, "/status"), {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify({
        status: status,
        commentary: commentary
      })
    }).then(function (resp) {
      if (!resp.ok) {
        return Promise.reject(resp.json());
      }

      return resp;
    }).catch(function (e) {
      return console.log('error update status programme ', e);
    });
  };
};

exports.updateProgrammeStatus = updateProgrammeStatus;