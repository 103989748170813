"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _global = _interopRequireDefault(require("aws-sdk/global"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var region = process.env.REACT_APP_AWS_REGION;

_global.default.config.update({
  region: region
});

var _default = _global.default;
exports.default = _default;