const developerSearchSchema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		adminId: { type: 'number' },
		email: { type: 'string' },
		lastName: { type: 'string' },
		phone: { type: 'string' },
		tradingName: { type: 'string' },
	},
	required: [
	],
};


export default developerSearchSchema;
