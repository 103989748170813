import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import FormAdmin from '@cecaz-immo/ui/dist/components/form/admin/admin';
import FormGroup from '@cecaz-immo/ui/dist/components/form/group/group';
import FormButton from '@cecaz-immo/ui/dist/components/form/button/button';
import FieldAdmin from '@cecaz-immo/ui/dist/components/form/field/admin';
import LocationSelect from '@cecaz-immo/app-client/dist/components/location/location-select';
import { WithRole } from '@cecaz-immo/app-developer/dist/components/security/security';
import * as Status from '@cecaz-immo/app-developer/dist/lib/status';

import AdminSelector from '../admin/selector';
import DeveloperSelector from '../developer/selector';

const ProgrammeSearchBar = ({
	data,
	loading,
	lp,
	onChangeAdmin,
	onChangeDeveloper,
	onChangeInput,
	onChangeLocation,
	onChangeStatus,
	onSubmit,
	roleSuperAdmin,
	schema,
}) => {
	const entity = lp ? 'Bien' : 'Programme';
	const user = lp ? 'Partenaire' : 'Promoteur';
	return (
		<FormAdmin
			onSubmit={onSubmit}
			className="FormAdmin-horizontal FormAdmin-search"
			data={data}
			schema={schema}
		>
			<FormGroup>
				<label>Statut</label>
				<Select
					className="Select-admin"
					joinValues
					value={data.status}
					name="status"
					placeholder="Sélectionner un statut"
					options={[
						{ value: Status.TO_CORRECT, label: Status.labels[Status.TO_CORRECT] },
						{ value: Status.WAITING, label: Status.labels[Status.WAITING] },
						{ value: Status.PUBLISHED, label: Status.labels[Status.PUBLISHED] },
						{ value: Status.FINISHED, label: Status.labels[Status.FINISHED] },
					]}
					onChange={onChangeStatus}
				/>
			</FormGroup>

			<WithRole role={roleSuperAdmin}>
				<FormGroup>
					<label>Admininistrateur</label>
					<AdminSelector adminId={data.adminId} onChangeAdmin={onChangeAdmin} />
				</FormGroup>
			</WithRole>
			<FormGroup>
				<label>{user}</label>
				<DeveloperSelector
					developerId={data.developerId}
					onChangeDeveloper={onChangeDeveloper}
					placeholder={user}
				/>
			</FormGroup>
			<FormGroup>
				<label>Localité</label>
				<LocationSelect
					className="Select-admin"
					geolocation={false}
					value={data.location}
					onChange={onChangeLocation}
				/>
			</FormGroup>
			<FormGroup>
				<label>{entity}</label>
				<FieldAdmin
					component="input"
					name="programme"
					onChange={onChangeInput}
					placeholder={entity}
					type="text"
					value={data.programme}
				/>
			</FormGroup>
			<FormGroup>
				<label>&nbsp;</label>
				<FormButton loading={loading} type="submit"><i className="fa fa-search" /></FormButton>
			</FormGroup>
		</FormAdmin>
	);
};

ProgrammeSearchBar.propTypes = {
	data: PropTypes.shape({}).isRequired,
	loading: PropTypes.bool,
	lp: PropTypes.bool,
	onChangeAdmin: PropTypes.func.isRequired,
	onChangeDeveloper: PropTypes.func.isRequired,
	onChangeInput: PropTypes.func.isRequired,
	onChangeLocation: PropTypes.func.isRequired,
	onChangeStatus: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	roleSuperAdmin: PropTypes.string.isRequired,
	schema: PropTypes.shape({}).isRequired,
};

ProgrammeSearchBar.defaultProps = {
	loading: false,
	lp: false,
};

export default ProgrammeSearchBar;
