"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactSimpleJsonschemaForm = require("react-simple-jsonschema-form");

require("./field.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var FormField = function FormField(_ref) {
  var className = _ref.className,
      children = _ref.children,
      customFieldError = _ref.customFieldError,
      errorMessages = _ref.errorMessages,
      name = _ref.name,
      props = _objectWithoutProperties(_ref, ["className", "children", "customFieldError", "errorMessages", "name"]);

  return _react.default.createElement("div", {
    className: "FormField ".concat(className || '')
  }, _react.default.createElement(_reactSimpleJsonschemaForm.Field, _extends({
    name: name
  }, props)), !customFieldError && _react.default.createElement(_reactSimpleJsonschemaForm.FieldError, {
    name: name,
    errorMessages: errorMessages
  }), children);
};

FormField.propTypes = {
  children: _propTypes.default.node,
  className: _propTypes.default.string,
  errorMessages: _propTypes.default.objectOf(_propTypes.default.func),
  name: _propTypes.default.string.isRequired,
  customFieldError: _propTypes.default.bool
};
FormField.defaultProps = {
  children: null,
  className: '',
  customFieldError: false,
  errorMessages: null
};
var _default = FormField;
exports.default = _default;