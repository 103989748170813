import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import Actions from '@cecaz-immo/app-developer/dist/components/actions/actions';
import FormAdmin from '@cecaz-immo/ui/dist/components/form/admin/admin';
import FormGroup from '@cecaz-immo/ui/dist/components/form/group/group';
import FormButton from '@cecaz-immo/ui/dist/components/form/button/button';
import FieldAdmin from '@cecaz-immo/ui/dist/components/form/field/admin';
import PageContent from '@cecaz-immo/ui/dist/components/page/content';

import { ROLE_SUPER_ADMIN } from '@cecaz-immo/app-developer/dist/lib/roles';
import { WithRole } from '@cecaz-immo/app-developer/dist/components/security/security';

import AdminSelector from '../../components/admin/selector';
import DeveloperEditList from '../../components/developer/edit/list/list';

import { requestSearchDevelopersAction } from '../../store/actions/developer';

import searchDevelopersSchema from '../../schemas/developer-search';


class Search extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: {
				adminId: null,
				email: '',
				lastName: '',
				phone: '',
				tradingName: '',
			},
			loading: false,
		};

		props.searchDevelopers().catch(console.error);

		this.onChange = this.onChange.bind(this);
		this.onChangeAdmin = this.onChangeAdmin.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		const { target } = e;

		this.setState(({ data }) => ({
			data: {
				...data,
				[target.name]: target.value,
			},
		}));
	}

	onChangeAdmin(admin) {
		const { data } = this.state;
		this.setState({
			data: { ...data, adminId: (admin && admin.value) || null },
		});
	}

	onSubmit(event) {
		event.preventDefault();
		const { searchDevelopers } = this.props;
		const { data } = this.state;

		this.setState({ loading: true });

		searchDevelopers(data)
			.catch((err) => {
				console.error(err);
				this.setState({ loading: false });
			})
			.then(() => {
				this.setState({ loading: false });
			});
	}

	render() {
		const { developers, lp } = this.props;
		const { data, loading } = this.state;
		const {
			adminId,
			email,
			lastName,
			phone,
			tradingName,
		} = data;


		return (
			<PageContent className="PageDeveloperSearch">
				<Actions>
					<Link to="/developer/edit" className="btn btn-add">
						Créer un {lp ? 'partenaire' : 'promoteur'} <i className="fa fa-plus-circle" />
					</Link>
				</Actions>
				<section className="section page-center">
					<header>
						<h1 className="section-title">{lp ? 'Partenaires' : 'Promoteurs'}</h1>
					</header>
					<FormAdmin
						onSubmit={this.onSubmit}
						className="FormAdmin-horizontal FormAdmin-search "
						schema={searchDevelopersSchema}
						data={data}
					>
						<WithRole role={ROLE_SUPER_ADMIN}>
							<FormGroup>
								<label>Admininistrateur</label>
								<AdminSelector adminId={adminId} onChangeAdmin={this.onChangeAdmin} />
							</FormGroup>
						</WithRole>

						<FormGroup>
							<label>Email</label>
							<FieldAdmin
								component="input"
								name="email"
								type="text"
								placeholder="Email"
								value={email}
								onChange={this.onChange}
							/>
						</FormGroup>
						<FormGroup>
							<label>Nom</label>
							<FieldAdmin
								component="input"
								name="lastName"
								type="text"
								placeholder="Nom"
								value={lastName}
								onChange={this.onChange}
							/>
						</FormGroup>
						<FormGroup>
							<label>Téléphone</label>
							<FieldAdmin
								component="input"
								name="phone"
								type="text"
								placeholder="Téléphone"
								value={phone}
								onChange={this.onChange}
							/>
						</FormGroup>
						<FormGroup>
							<label>Nom commercial</label>
							<FieldAdmin
								component="input"
								name="tradingName"
								onChange={this.onChange}
								placeholder="Nom commercial"
								type="text"
								value={tradingName}
							/>
						</FormGroup>
						<FormGroup>
							<label>&nbsp;</label>
							<div className="Field FieldAdmin">
								<FormButton
									loading={loading}
									type="submit"
								>
									<i className="fa fa-search" />
								</FormButton>
							</div>
						</FormGroup>
					</FormAdmin>
				</section>
				<section className="section page-center last-section">
					<span>Nombre de {lp ? 'partenaires' : 'promoteurs'} : { developers.length }</span>
					<DeveloperEditList developers={developers} lp={lp} />
				</section>
			</PageContent>
		);
	}
}

Search.propTypes = {
	developers: PropTypes.arrayOf(PropTypes.shape({})),
	lp: PropTypes.bool,
	searchDevelopers: PropTypes.func.isRequired,
};

Search.defaultProps = {
	developers: [],
	lp: false,
};

const mapStateToProps = state => ({
	developers: state.developer.developersSearched,
	admins: state.admin.adminsSearched,
});

const mapDispatchToProps = dispatch => ({
	searchDevelopers: params => dispatch(requestSearchDevelopersAction(params)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Search);
