"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

require("./programme-lots.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ProgrammeLots = function ProgrammeLots(_ref) {
  var lots = _ref.lots,
      title = _ref.title;
  return _react.default.createElement("div", {
    className: "ProgrammeLots"
  }, _react.default.createElement("h2", {
    className: "ProgrammeLots-title"
  }, title), _react.default.createElement("table", {
    className: "ProgrammeLots-table"
  }, _react.default.createElement("thead", null, _react.default.createElement("tr", null, _react.default.createElement("td", null, "Pi\xE8ces"), _react.default.createElement("td", null, "Surface"), _react.default.createElement("td", null, "Prix"), _react.default.createElement("td", null, "Parking"), _react.default.createElement("td", null, "Quantit\xE9"))), _react.default.createElement("tbody", null, lots.map(function (lot) {
    return _react.default.createElement("tr", {
      key: lot.id
    }, _react.default.createElement("td", null, lot.rooms), _react.default.createElement("td", null, lot.area), _react.default.createElement("td", null, lot.price), _react.default.createElement("td", null, lot.parking), _react.default.createElement("td", null, lot.amount));
  }))));
};

ProgrammeLots.propTypes = {
  lots: _propTypes.default.arrayOf(_propTypes.default.shape({
    id: _propTypes.default.number,
    rooms: _propTypes.default.number,
    area: _propTypes.default.shape,
    price: _propTypes.default.number,
    parking: _propTypes.default.string,
    amount: _propTypes.default.number
  })).isRequired,
  title: _propTypes.default.string.isRequired
};
var _default = ProgrammeLots;
exports.default = _default;