import PropTypes from 'prop-types';
import React from 'react';

import './field.scss';

const UserListItemField = ({ className, label, value }) => (
	<p className={`UserListItemField ${className}`}>
		<span className="UserListItemField-label">{label}</span>
		<em className="UserListItemField-value">
			{value}
		</em>
	</p>
);

UserListItemField.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string.isRequired,
	value: PropTypes.node,
};

UserListItemField.defaultProps = {
	className: '',
	value: '',
};

export default UserListItemField;
