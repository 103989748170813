"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _editProgramme = require("../actions/edit-programme");

var lots = function lots() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState = Object.assign([], state);

  switch (action.type) {
    case _editProgramme.EDIT_PROGRAMME_LOT_UPDATE_ACTION:
      newState[action.index] = Object.assign({}, newState[action.index], action.lot);
      break;

    case _editProgramme.EDIT_PROGRAMME_LOT_CREATE_ACTION:
      newState.push(action.lot);
      break;

    case _editProgramme.EDIT_PROGRAMME_LOT_DELETE_ACTION:
      newState.splice(action.index, 1);
      break;

    default:
      return state;
  }

  return newState;
};

var defaultState = {
  yearDelivery: "".concat(new Date().getFullYear()),
  quarterDelivery: 1,
  tags: []
};

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState;

  switch (action.type) {
    case _editProgramme.EDIT_PROGRAMME_CREATE_ACTION:
      return defaultState;

    case _editProgramme.EDIT_PROGRAMME_UPDATE_ACTION:
      newState = Object.assign({}, state, action.programme);
      return newState;

    case _editProgramme.EDIT_PROGRAMME_LOT_UPDATE_ACTION:
    case _editProgramme.EDIT_PROGRAMME_LOT_CREATE_ACTION:
    case _editProgramme.EDIT_PROGRAMME_LOT_DELETE_ACTION:
      newState = Object.assign({}, state);
      newState.lots = lots(state.lots, action);
      return newState;

    case _editProgramme.EDIT_PROGRAMME_FILES_UPDATE_ACTION:
      newState = Object.assign({}, state);
      newState.files = action.files;
      return newState;

    default:
      return state;
  }
};

exports.default = _default;