"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.signInSchema = exports.resetPasswordWithCodeSchema = exports.resetPasswordSchema = exports.forgotPasswordSchema = void 0;

var _types = require("./types");

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var forgotPasswordSchema = {
  type: 'object',
  properties: {
    email: {
      type: 'string',
      format: 'email',
      maxLength: 255
    }
  },
  required: ['email']
};
exports.forgotPasswordSchema = forgotPasswordSchema;
var resetPasswordSchema = {
  additionalProperties: false,
  type: 'object',
  properties: {
    password: _types.password,
    passwordConfirm: {
      type: 'string',
      const: {
        $data: '1/password'
      }
    }
  },
  required: ['password', 'passwordConfirm']
};
exports.resetPasswordSchema = resetPasswordSchema;
var resetPasswordWithCodeSchema = {
  additionalProperties: false,
  type: 'object',
  properties: _objectSpread({}, resetPasswordSchema.properties, {
    code: {
      type: 'string'
    }
  }),
  required: _toConsumableArray(resetPasswordSchema.required).concat(['code'])
};
exports.resetPasswordWithCodeSchema = resetPasswordWithCodeSchema;
var signInSchema = {
  type: 'object',
  properties: {
    username: {
      type: 'string',
      format: 'email',
      maxLength: 255
    },
    password: {
      type: 'string'
    }
  },
  required: ['username', 'password']
};
exports.signInSchema = signInSchema;