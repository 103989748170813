"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setSessionAction = exports.invalidateSessionAction = exports.clearSessionAction = exports.SESSION_SET_SESSION_ACTION = exports.SESSION_INVALIDATE_SESSION_ACTION = exports.SESSION_CLEAR_SESSION_ACTION = void 0;
var SESSION_CLEAR_SESSION_ACTION = 'SESSION_CLEAR_SESSION_ACTION';
exports.SESSION_CLEAR_SESSION_ACTION = SESSION_CLEAR_SESSION_ACTION;
var SESSION_INVALIDATE_SESSION_ACTION = 'SESSION_INVALIDATE_SESSION_ACTION';
exports.SESSION_INVALIDATE_SESSION_ACTION = SESSION_INVALIDATE_SESSION_ACTION;
var SESSION_SET_SESSION_ACTION = 'SESSION_SET_SESSION_ACTION';
exports.SESSION_SET_SESSION_ACTION = SESSION_SET_SESSION_ACTION;

var clearSessionAction = function clearSessionAction() {
  return {
    type: SESSION_CLEAR_SESSION_ACTION
  };
};

exports.clearSessionAction = clearSessionAction;

var invalidateSessionAction = function invalidateSessionAction() {
  return {
    type: SESSION_INVALIDATE_SESSION_ACTION
  };
};

exports.invalidateSessionAction = invalidateSessionAction;

var setSessionAction = function setSessionAction(session) {
  return {
    type: SESSION_SET_SESSION_ACTION,
    session: session
  };
};

exports.setSessionAction = setSessionAction;