"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changePassword = exports.confirmResetPassword = exports.completeNewPasswordChallenge = exports.signOut = exports.signIn = exports.getFullSession = exports.getDecodedIdToken = exports.getUserAttributes = exports.getSession = exports.getCognitoUser = exports.resetSession = exports.NewPasswordRequiredError = void 0;

var _amazonCognitoIdentityJs = require("amazon-cognito-identity-js");

require("./aws");

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

var _process$env = process.env,
    REACT_APP_AWS_COGNITO_CLIENT_ID = _process$env.REACT_APP_AWS_COGNITO_CLIENT_ID,
    REACT_APP_AWS_COGNITO_USER_POOL_ID = _process$env.REACT_APP_AWS_COGNITO_USER_POOL_ID; //Connection

var UserPool = new _amazonCognitoIdentityJs.CognitoUserPool({
  ClientId: REACT_APP_AWS_COGNITO_CLIENT_ID,
  UserPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID
});

var NewPasswordRequiredError = function NewPasswordRequiredError(userAttributes) {
  _classCallCheck(this, NewPasswordRequiredError);

  this.userAttributes = userAttributes;
};

exports.NewPasswordRequiredError = NewPasswordRequiredError;

var initCognitoUser = function initCognitoUser(credentials) {
  var password = credentials.password;
  var username = credentials.username;
  username = (username || '').toLowerCase();
  var result = {};
  result.cognitoUser = new _amazonCognitoIdentityJs.CognitoUser({
    Username: username,
    Pool: UserPool
  });

  if (password) {
    result.authenticationDetails = new _amazonCognitoIdentityJs.AuthenticationDetails({
      Username: username,
      Password: password
    });
  }

  return result;
};

var defaultSession = {
  cognitoUser: null,
  cognitoSession: null,
  cognitoUserAttributes: null,
  decodedIdToken: null
};
var _session = {};

var resetSession = function resetSession() {
  Object.assign(_session, defaultSession);
};

exports.resetSession = resetSession;

var getCognitoUser = function getCognitoUser() {
  return new Promise(function (resolve) {
    _session.cognitoUser = _session.cognitoUser || UserPool.getCurrentUser();

    if (!_session.cognitoUser) {
      throw new Error('User is not connected.');
    }

    resolve(_session.cognitoUser);
  });
};

exports.getCognitoUser = getCognitoUser;

var getSession = function getSession() {
  return getCognitoUser().then(function (cognitoUser) {
    return new Promise(function (resolve, reject) {
      if (_session.cognitoSession && _session.cognitoSession.isValid()) {
        resolve(_session.cognitoSession);
        return;
      }

      cognitoUser.getSession(function (err, cognitoSession) {
        if (err) {
          reject(err);
          return;
        }

        if (!cognitoSession.isValid()) {
          throw new Error('Session invalid.');
        }

        _session.cognitoSession = cognitoSession;
        resolve(cognitoSession);
      });
    });
  }).catch(function (error) {
    resetSession();
    throw error;
  });
};

exports.getSession = getSession;

var getUserAttributes = function getUserAttributes() {
  return getSession().then(function () {
    return new Promise(function (resolve, reject) {
      if (_session.cognitoUserAttributes) {
        resolve(_session.cognitoUserAttributes);
        return;
      }

      _session.cognitoUser.getUserAttributes(function (error, attributes) {
        if (error) {
          reject(error);
          return;
        }

        var cognitoUserAttributes = {};
        attributes.forEach(function (_ref) {
          var Name = _ref.Name,
              Value = _ref.Value;
          cognitoUserAttributes[Name] = Value;
        });
        _session.cognitoUserAttributes = cognitoUserAttributes;
        resolve(cognitoUserAttributes);
      });
    });
  });
};

exports.getUserAttributes = getUserAttributes;

var getDecodedIdToken = function getDecodedIdToken() {
  return getSession().then(function (cognitoSession) {
    return new Promise(function (resolve) {
      if (_session.decodedIdToken) {
        resolve(_session.decodedIdToken);
        return;
      }

      var jwtToken = cognitoSession.getIdToken().getJwtToken();
      var jwtPayload = jwtToken.split('.')[1];
      var decodedIdToken = JSON.parse(atob(jwtPayload).toString('utf8'));
      _session.decodedIdToken = decodedIdToken;
      resolve(decodedIdToken);
    });
  });
};

exports.getDecodedIdToken = getDecodedIdToken;

var getFullSession = function getFullSession() {
  return getCognitoUser().then(function () {
    return getSession();
  }).then(function () {
    return getDecodedIdToken();
  }).then(function () {
    return getUserAttributes();
  }).then(function () {
    return _session;
  });
};

exports.getFullSession = getFullSession;

var signIn = function signIn(username, password) {
  resetSession();
  return new Promise(function (resolve, reject) {
    var _initCognitoUser = initCognitoUser({
      username: username,
      password: password
    }),
        cognitoUser = _initCognitoUser.cognitoUser,
        authenticationDetails = _initCognitoUser.authenticationDetails;

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function onSuccess(result) {
        resolve(result);
      },
      onFailure: reject,
      newPasswordRequired: function newPasswordRequired(userAttributes) {
        reject(new NewPasswordRequiredError(userAttributes));
      }
    });
  });
};

exports.signIn = signIn;

var signOut = function signOut() {
  return getCognitoUser().then(function (cognitoUser) {
    cognitoUser.signOut();
    resetSession();
    return cognitoUser;
  });
};

exports.signOut = signOut;

var completeNewPasswordChallenge = function completeNewPasswordChallenge(username, password, newPassword) {
  resetSession();
  return new Promise(function (resolve, reject) {
    var _initCognitoUser2 = initCognitoUser({
      username: username,
      password: password
    }),
        cognitoUser = _initCognitoUser2.cognitoUser,
        authenticationDetails = _initCognitoUser2.authenticationDetails;

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function onSuccess() {
        return Promise.reject(new Error('Password challenge already completed'));
      },
      onFailure: reject,
      onMFARequired: reject,
      newPasswordRequired: function newPasswordRequired() {
        cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
          onSuccess: function onSuccess(result) {
            resolve(result);
          },
          onFailure: reject
        });
      }
    });
  });
};

exports.completeNewPasswordChallenge = completeNewPasswordChallenge;

var confirmResetPassword = function confirmResetPassword(username, password, code) {
  return new Promise(function (resolve, reject) {
    var _initCognitoUser3 = initCognitoUser({
      username: username
    }),
        cognitoUser = _initCognitoUser3.cognitoUser;

    cognitoUser.confirmPassword(code, password, {
      onSuccess: resolve,
      onFailure: reject
    });
  });
};

exports.confirmResetPassword = confirmResetPassword;

var changePassword = function changePassword(username, oldPassword, newPassword) {
  return new Promise(function (resolve, reject) {
    var _initCognitoUser4 = initCognitoUser({
      username: username,
      password: oldPassword
    }),
        cognitoUser = _initCognitoUser4.cognitoUser,
        authenticationDetails = _initCognitoUser4.authenticationDetails;

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function onSuccess() {
        cognitoUser.changePassword(oldPassword, newPassword, function (err, result) {
          if (err) {
            reject(err);
            return;
          }

          resolve(result);
        });
      },
      onFailure: reject
    });
  });
};

exports.changePassword = changePassword;