"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _modal = _interopRequireDefault(require("../modal"));

require("./confirm.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var ModalConfirm = function ModalConfirm(_ref) {
  var btnCancelLabel = _ref.btnCancelLabel,
      btnConfirmLabel = _ref.btnConfirmLabel,
      message = _ref.message,
      onCancel = _ref.onCancel,
      onConfirm = _ref.onConfirm,
      title = _ref.title,
      rest = _objectWithoutProperties(_ref, ["btnCancelLabel", "btnConfirmLabel", "message", "onCancel", "onConfirm", "title"]);

  return _react.default.createElement(_modal.default, _extends({
    onRequestClose: onCancel,
    title: title
  }, rest), _react.default.createElement("form", {
    onSubmit: function onSubmit(e) {
      e.preventDefault();
    }
  }, _react.default.createElement("p", {
    className: "ModalConfirm-message"
  }, message), _react.default.createElement("footer", {
    className: "ModalConfirm-footer"
  }, _react.default.createElement("input", {
    className: "ModalConfirm-btn btn",
    onClick: onConfirm,
    type: "submit",
    value: btnConfirmLabel
  }), _react.default.createElement("input", {
    className: "ModalConfirm-btn btn",
    onClick: onCancel,
    type: "submit",
    value: btnCancelLabel
  }))));
};

ModalConfirm.propTypes = {
  btnCancelLabel: _propTypes.default.node,
  btnConfirmLabel: _propTypes.default.node,
  message: _propTypes.default.node,
  onCancel: _propTypes.default.func,
  onConfirm: _propTypes.default.func,
  title: _propTypes.default.node
};
ModalConfirm.defaultProps = {
  btnCancelLabel: 'Annuler',
  btnConfirmLabel: 'Continuer',
  message: 'Voulez-vous continuer ?',
  onCancel: null,
  onConfirm: null,
  title: 'Attention'
};
var _default = ModalConfirm;
exports.default = _default;