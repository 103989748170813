import { combineReducers } from 'redux';

import editProgramme from '@cecaz-immo/app-developer/dist/store/reducers/edit-programme';
import programme from '@cecaz-immo/app-client/dist/store/reducers/programme';
import session from '@cecaz-immo/app-developer/dist/store/reducers/session';

import editAdmin from './edit-admin';
import admin from './admin';
import editDeveloper from './edit-developer';
import developer from './developer';

const app = combineReducers({
	editAdmin,
	admin,
	editDeveloper,
	editProgramme,
	developer,
	programme,
	session,
});

export default app;
