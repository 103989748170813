"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _types = require("./types");

var userSchema = {
  type: 'object',
  properties: {
    password: _types.password,
    passwordConfirm: {
      type: 'string',
      const: {
        $data: '1/password'
      }
    },
    oldPassword: {
      type: 'string'
    }
  },
  required: ['password', 'passwordConfirm', 'oldPassword']
};
var _default = userSchema;
exports.default = _default;