import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Select from 'react-select';

import { requestSearchDevelopersAction } from '../../store/actions/developer';

import './selector.scss';

class DeveloperSelector extends PureComponent {
	constructor(props) {
		super(props);
		props.searchDevelopers().catch(console.error);

		this.getDeveloperOptions = this.getDeveloperOptions.bind(this);
	}

	getDeveloperOptions() {
		const { developers } = this.props;
		return developers.map(a => (
			{
				value: a.id,
				label: `${a.lastName} ${a.firstName}`,
			}));
	}

	render() {
		const {
			developerId,
			onChangeDeveloper,
			...props
		} = this.props;

		return (
			<Select
				className="Select-developer"
				joinValues
				value={developerId}
				name="developerId"
				options={this.getDeveloperOptions()}
				onChange={onChangeDeveloper}
				{...props}
			/>
		);
	}
}

DeveloperSelector.propTypes = {
	developerId: PropTypes.number,
	developers: PropTypes.arrayOf(PropTypes.shape({})),
	searchDevelopers: PropTypes.func.isRequired,
	onChangeDeveloper: PropTypes.func.isRequired,
};

DeveloperSelector.defaultProps = {
	developerId: null,
	developers: [],
};

const mapStateToProps = state => ({
	developers: state.developer.developersSearched,
});

const mapDispatchToProps = dispatch => ({
	searchDevelopers: () => dispatch(requestSearchDevelopersAction({})),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(DeveloperSelector);
