"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.labels = exports.FINISHED = exports.PUBLISHED = exports.WAITING = exports.TO_CORRECT = void 0;

var _labels;

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var TO_CORRECT = 'TO_CORRECT';
exports.TO_CORRECT = TO_CORRECT;
var WAITING = 'WAITING';
exports.WAITING = WAITING;
var PUBLISHED = 'PUBLISHED';
exports.PUBLISHED = PUBLISHED;
var FINISHED = 'FINISHED';
exports.FINISHED = FINISHED;
var labels = (_labels = {}, _defineProperty(_labels, TO_CORRECT, 'À corriger'), _defineProperty(_labels, WAITING, 'En attente de validation'), _defineProperty(_labels, PUBLISHED, 'Publié'), _defineProperty(_labels, FINISHED, 'Terminé'), _labels);
exports.labels = labels;