"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _content = _interopRequireDefault(require("@cecaz-immo/ui/dist/components/page/content"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _global = global,
    history = _global.history;

var _default = function _default() {
  return _react.default.createElement(_content.default, {
    className: "PageForbidden"
  }, _react.default.createElement("div", {
    className: "page-center section last-section"
  }, _react.default.createElement("p", {
    className: "text-center"
  }, "Vous n'avez pas les autorisations n\xE9cessaires pour effectuer cette action."), _react.default.createElement("p", {
    className: "text-center"
  }, _react.default.createElement("button", {
    onClick: function onClick() {
      history.back();
    },
    className: "btn",
    type: "button"
  }, "Retour"))));
};

exports.default = _default;