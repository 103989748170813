import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import Actions from '@cecaz-immo/app-developer/dist/components/actions/actions';
import PageContent from '@cecaz-immo/ui/dist/components/page/content';

import AdminEdit from '../../components/admin/edit/edit';
import {
	requestGetEditAdminAction,
	requestSaveEditAdminAction,
	resetEditAdminAction,
	updateEditAdminAction,
} from '../../store/actions/edit-admin';

class Edit extends React.Component {
	constructor(props) {
		super(props);

		const { getAdmin } = this.props;

		getAdmin().catch(console.error);
	}

	render() {
		const {
			admin,
			saveAdmin,
			updateAdmin,
		} = this.props;

		return (
			<PageContent className="PageAdminEdit">
				<Actions>
					<Link to="/admin" className="btn btn-back">
						<i className="fa fa-long-arrow-alt-left" />
					</Link>
				</Actions>
				<section className="section page-center">
					<header>
						<h1 className="section-title">
							{
								(admin.id && "Edition d'un administrateur")
								|| "Création d'un administrateur"
							}
						</h1>
					</header>
					<AdminEdit
						admin={admin}
						saveAdmin={saveAdmin}
						updateAdmin={updateAdmin}
					/>
				</section>
			</PageContent>
		);
	}
}

Edit.propTypes = {
	admin: PropTypes.shape({}),
	getAdmin: PropTypes.func.isRequired,
	saveAdmin: PropTypes.func.isRequired,
	updateAdmin: PropTypes.func.isRequired,
};

Edit.defaultProps = {
	admin: {},
};

const mapStateToProps = state => ({
	admin: state.editAdmin,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getAdmin: () => {
		const id = Number.parseInt(ownProps.match.params.id, 10);

		if (!id) {
			dispatch(resetEditAdminAction());
			return Promise.resolve();
		}

		return dispatch(requestGetEditAdminAction(id));
	},
	saveAdmin: () => dispatch(requestSaveEditAdminAction()),
	updateAdmin: admin => dispatch(updateEditAdminAction(admin)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Edit);
