"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactRouterDom = require("react-router-dom");

require("./nav-item.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var NavItem = function NavItem(_ref) {
  var current = _ref.current,
      id = _ref.id,
      label = _ref.label,
      link = _ref.link;
  return _react.default.createElement("li", {
    className: "NavItem ".concat(current === id ? 'active' : '')
  }, _react.default.createElement(_reactRouterDom.Link, {
    to: link
  }, label));
};

NavItem.propTypes = {
  current: _propTypes.default.string,
  id: _propTypes.default.string.isRequired,
  label: _propTypes.default.node.isRequired,
  link: _propTypes.default.string.isRequired
};
NavItem.defaultProps = {
  current: ''
};
var _default = NavItem;
exports.default = _default;