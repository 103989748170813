import {
	EDIT_ADMIN_RESET_ADMIN,
	EDIT_ADMIN_UPDATE_ADMIN,
} from '../actions/edit-admin';

export default (state = {}, action) => {
	switch (action.type) {
	case EDIT_ADMIN_RESET_ADMIN:
		return {};
	case EDIT_ADMIN_UPDATE_ADMIN:
		return Object.assign({}, state, action.admin);
	default:
		return state;
	}
};
