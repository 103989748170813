"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

require("./alert-message.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var AlertMessage = function AlertMessage(_ref) {
  var className = _ref.className,
      display = _ref.display,
      message = _ref.message,
      success = _ref.success,
      title = _ref.title;
  return display ? _react.default.createElement("section", {
    className: "AlertMessage ".concat(className || '')
  }, _react.default.createElement("div", {
    className: "AlertMessage-content"
  }, _react.default.createElement("header", {
    className: "header ".concat(success && 'success' || '')
  }, title), _react.default.createElement("p", {
    className: "content"
  }, message))) : null;
};

AlertMessage.propTypes = {
  className: _propTypes.default.string,
  title: _propTypes.default.string.isRequired,
  message: _propTypes.default.string.isRequired,
  display: _propTypes.default.bool,
  success: _propTypes.default.bool
};
AlertMessage.defaultProps = {
  className: '',
  display: false,
  success: false
};
var _default = AlertMessage;
exports.default = _default;