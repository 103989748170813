"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

var _content = _interopRequireDefault(require("@cecaz-immo/ui/dist/components/page/content"));

var _signIn = _interopRequireDefault(require("../../components/auth/forms/sign-in"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var SignIn = function SignIn(_ref) {
  var developer = _ref.developer,
      history = _ref.history,
      lp = _ref.lp;
  return _react.default.createElement(_content.default, {
    className: "PageAuthSignIn"
  }, _react.default.createElement("div", {
    className: "section last-section"
  }, _react.default.createElement("header", null, _react.default.createElement("h1", {
    className: "section-title"
  }, "Espace ", developer ? lp ? 'Partenaire' : 'Promoteur' : 'Administrateur')), _react.default.createElement("div", {
    className: "page-center"
  }, _react.default.createElement(_signIn.default, {
    history: history
  }))));
};

SignIn.propTypes = {
  developer: _propTypes.default.bool,
  history: _propTypes.default.shape({}).isRequired,
  lp: _propTypes.default.bool
};
SignIn.defaultProps = {
  developer: false,
  lp: false
};
var _default = SignIn;
exports.default = _default;