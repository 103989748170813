import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import Actions from '@cecaz-immo/app-developer/dist/components/actions/actions';
import DeveloperEdit from '@cecaz-immo/app-developer/dist/components/developer/edit/edit';
import PageContent from '@cecaz-immo/ui/dist/components/page/content';

import {
	requestGetEditDeveloperAction,
	requestSaveEditDeveloperAction,
	resetEditDeveloperAction,
	updateEditDeveloperAction,
} from '../../store/actions/edit-developer';

class Edit extends React.Component {
	constructor(props) {
		super(props);

		const { getDeveloper } = this.props;

		getDeveloper().catch(console.error);
	}

	render() {
		const {
			developer,
			lp,
			updateDeveloper,
			saveDeveloper,
		} = this.props;

		const entity = lp ? 'partenaire' : 'promoteur';
		return (
			<PageContent className="PageDeveloperEdit">
				<Actions>
					<Link to="/developer" className="btn btn-back">
						<i className="fa fa-long-arrow-alt-left" />
					</Link>
				</Actions>
				<section className="section page-center">
					<header>
						<h1 className="section-title">
							{
								(developer.id && `Edition d'un ${entity}`)
								|| `Création d'un ${entity}`
							}
						</h1>
					</header>
					<DeveloperEdit
						developer={developer}
						lp={lp}
						updateDeveloper={updateDeveloper}
						saveDeveloper={saveDeveloper}
					/>
				</section>
			</PageContent>
		);
	}
}

Edit.propTypes = {
	developer: PropTypes.shape({
		id: PropTypes.number,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		tradingName: PropTypes.string,
		phone: PropTypes.string,
	}),
	getDeveloper: PropTypes.func.isRequired,
	lp: PropTypes.bool,
	saveDeveloper: PropTypes.func.isRequired,
	updateDeveloper: PropTypes.func.isRequired,
};

Edit.defaultProps = {
	developer: {},
	lp: false,
};

const mapStateToProps = state => ({
	developer: state.editDeveloper,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getDeveloper: () => {
		const id = Number.parseInt(ownProps.match.params.id, 10);

		if (!id) {
			dispatch(resetEditDeveloperAction());
			return Promise.resolve();
		}

		return dispatch(requestGetEditDeveloperAction(id));
	},
	saveDeveloper: () => dispatch(requestSaveEditDeveloperAction()),
	updateDeveloper: admin => dispatch(updateEditDeveloperAction(admin)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Edit);
