"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _content = _interopRequireDefault(require("@cecaz-immo/ui/dist/components/page/content"));

var _forgotPassword = _interopRequireDefault(require("../../components/auth/forms/forgot-password"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ForgotPassword = function ForgotPassword(_ref) {
  var developer = _ref.developer,
      lp = _ref.lp,
      history = _ref.history;
  return _react.default.createElement(_content.default, {
    className: "PageAuthForgotPassword"
  }, _react.default.createElement("div", {
    className: "section last-section"
  }, _react.default.createElement("header", null, _react.default.createElement("h1", {
    className: "section-title"
  }, "Espace ", developer ? lp ? 'Partenaire' : 'Promoteur' : 'Administrateur')), _react.default.createElement("div", {
    className: "page-center"
  }, _react.default.createElement(_forgotPassword.default, {
    history: history,
    userType: developer ? 'developer' : 'admin'
  }))));
};

ForgotPassword.propTypes = {
  developer: _propTypes.default.bool,
  history: _propTypes.default.shape({}).isRequired,
  lp: _propTypes.default.bool
};
ForgotPassword.defaultProps = {
  developer: false,
  lp: false
};
var _default = ForgotPassword;
exports.default = _default;