import { fetchWithSession } from '@cecaz-immo/app-developer/dist/lib/session';
import { transformProgramme } from '@cecaz-immo/app-client/dist/store/actions/programme';

const { REACT_APP_API_URL } = process.env;

export const SET_ADMINS_SEARCHED_ACTION = 'SET_ADMINS_SEARCHED_ACTION';
export const SET_CURRENT_ADMIN_ACTION = 'SET_CURRENT_ADMIN_ACTION';
export const UPDATE_CURRENT_ADMIN_LAST_DEVELOPERS = 'UPDATE_CURRENT_ADMIN_LAST_DEVELOPERS';
export const UPDATE_CURRENT_ADMIN_WAITING_PROGRAMMES = 'UPDATE_CURRENT_ADMIN_WAITING_PROGRAMMES';

const setAdminsSearchedAction = admins => ({
	type: SET_ADMINS_SEARCHED_ACTION,
	admins,
});

const setCurrentAdminAction = admin => ({
	type: SET_CURRENT_ADMIN_ACTION,
	admin,
});

const updateCurrentAdminLastDevelopersAction = developers => ({
	type: UPDATE_CURRENT_ADMIN_LAST_DEVELOPERS,
	developers,
});

const updateCurrentAdminWaitingProgrammesAction = programmes => ({
	type: UPDATE_CURRENT_ADMIN_WAITING_PROGRAMMES,
	programmes,
});

export const requestGetCurrentAdminAction = () => dispatch => fetchWithSession(
	`${REACT_APP_API_URL}/admin`,
	{
		headers: {
			'Cache-Control': 'no-cache',
		},
	},
)
	.then(resp => resp.json())
	.then((result) => {
		dispatch(setCurrentAdminAction(result));
	});

export const requestGetCurrentAdminWaitingProgrammesAction = () => dispatch => fetchWithSession(
	`${REACT_APP_API_URL}/admin/programmes/status/waiting`,
	{
		headers: {
			'Cache-Control': 'no-cache',
		},
	},
)
	.then(resp => resp.json())
	.then((results) => {
		if (results) {
			results.forEach((prog) => {
				transformProgramme(prog);
			});
		}

		dispatch(updateCurrentAdminWaitingProgrammesAction(results));
	});

export const requestGetCurrentAdminLastDevelopersAction = () => dispatch => fetchWithSession(
	`${REACT_APP_API_URL}/admin/promoteurs/derniers`,
	{
		headers: {
			'Cache-Control': 'no-cache',
		},
	},
)
	.then(resp => resp.json())
	.then((result) => {
		dispatch(updateCurrentAdminLastDevelopersAction(result));
	});

export const requestSearchAdminsAction = data => (dispatch) => {
	let params = '';
	if (data) {
		params += '?';

		Object.keys(data).forEach((property) => {
			if (!data[property]) {
				return;
			}
			params += `${property}=${data[property]}&`;
		});
	}

	return fetchWithSession(
		`${REACT_APP_API_URL}/admin/rechercher${params}`,
		{
			headers: {
				'Cache-Control': 'no-cache',
			},
		},
	)
		.then(resp => resp.json())
		.then((result) => {
			dispatch(setAdminsSearchedAction(result));
		});
};
