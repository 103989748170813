"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFileUrl = void 0;
var REACT_APP_AWS_S3_URL = process.env.REACT_APP_AWS_S3_URL; // eslint-disable-next-line import/prefer-default-export

var getFileUrl = function getFileUrl(file) {
  return encodeURI(REACT_APP_AWS_S3_URL + file.name);
};

exports.getFileUrl = getFileUrl;