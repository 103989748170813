"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

var _article = _interopRequireDefault(require("@cecaz-immo/ui/dist/components/legal/article"));

var _section = _interopRequireDefault(require("@cecaz-immo/ui/dist/components/legal/section"));

var _table = _interopRequireDefault(require("@cecaz-immo/ui/dist/components/legal/table"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var LegalMentions = function LegalMentions(_ref) {
  var appEmailPublic = _ref.appEmailPublic,
      appWWW = _ref.appWWW;
  return _react.default.createElement("section", {
    className: "page-center section last-section"
  }, _react.default.createElement(_section.default, null, _react.default.createElement("span", {
    className: "text-center"
  }, appWWW), _react.default.createElement("h1", {
    className: "section-title"
  }, "Mentions l\xE9gales"), _react.default.createElement(_article.default, null, _react.default.createElement("h2", null, "EDITEUR DU SITE"), _react.default.createElement("p", null, "Ce site est \xE9dit\xE9 par la Caisse d\u2019Epargne et de Pr\xE9voyance C\xF4te d\u2019Azur, ci-apr\xE8s \xAB l\u2019\xE9diteur \xBB"), _react.default.createElement(_table.default, null, _react.default.createElement("tr", null, _react.default.createElement("th", {
    scope: "row"
  }, "Raison sociale :"), _react.default.createElement("td", null, "Caisse d\u2019Epargne et de Pr\xE9voyance C\xF4te d\u2019Azur, soci\xE9t\xE9 anonyme coop\xE9rative \xE0 directoire et conseil d\u2019orientation et de surveillance, r\xE9gie par les articles L.512-85 et suivants du Code mon\xE9taire et financier \u2013 Capital social 395.033.520 euros \u2013 455, promenade des Anglais, 06200 Nice \u2013 384 402 871 RCS NICE \u2013 Interm\xE9diaire d\u2019assurance, immatricul\xE9 \xE0 l\u2019ORIAS sous le n\xB0 07 002 199.", _react.default.createElement("br", null), _react.default.createElement("br", null), "Titulaire de la carte professionnelle Transactions sur immeubles et fonds de commerce, sans perception d\u2019effets ou valeurs, n\xB0 CPI 0605 2017 000 019 152 d\xE9livr\xE9e par la CCI Nice-C\xF4te d\u2019Azur, garantie par la Compagnie Europ\xE9enne de Garanties et Cautions, 16 rue Hoche, Tour KUPKA B, TSA 39999, 92919 LA DEFENSE Cedex.")), _react.default.createElement("tr", null, _react.default.createElement("th", {
    scope: "row"
  }, "Forme sociale :"), _react.default.createElement("td", null, "Soci\xE9t\xE9 anonyme coop\xE9rative \xE0 directoire et conseil d\u2019orientation et de surveillance")), _react.default.createElement("tr", null, _react.default.createElement("th", {
    scope: "row"
  }, "Si\xE8ge social :"), _react.default.createElement("td", null, "455, promenade des Anglais, 06200 Nice.")), _react.default.createElement("tr", null, _react.default.createElement("th", {
    scope: "row"
  }, "N\xB0 SIREN :"), _react.default.createElement("td", null, "384402871")), _react.default.createElement("tr", null, _react.default.createElement("th", {
    scope: "row"
  }, "N\xB0 ind. d\u2019ident. Intracommunautaire : "), _react.default.createElement("td", null, "FR6938440287100543")), _react.default.createElement("tr", null, _react.default.createElement("th", {
    scope: "row"
  }, "Directeur de publication :"), _react.default.createElement("td", null, "Fran\xE7ois Codet")), _react.default.createElement("tr", null, _react.default.createElement("th", {
    scope: "row"
  }, "Contact :"), _react.default.createElement("td", null, appEmailPublic, _react.default.createElement("br", null), "04 93 18 40 00")), _react.default.createElement("tr", null, _react.default.createElement("th", {
    scope: "row"
  }, "H\xE9bergeur"), _react.default.createElement("td", null, "D\xE9nomination : Amazon Web Services - Paris ", _react.default.createElement("br", null), "Adresse : 31 place des Corolles, 92098 PARIS \u2013 LA DEFENSE Cedex"))), _react.default.createElement("p", null, "L\u2019Editeur est un \xE9tablissement de cr\xE9dit de droit fran\xE7ais agr\xE9\xE9 en qualit\xE9 de banque prestataire de services d\u2019investissement, conform\xE9ment aux dispositions du Code mon\xE9taire et financier et contr\xF4l\xE9e par la Banque Centrale Europ\xE9enne (Sonnemannstrasse 20, D-60314 Frankfurt-am-Main, Allemagne).", _react.default.createElement("br", null), "L\u2019Editeur est adh\xE9rent aupr\xE8s de la FBF - F\xE9d\xE9ration Bancaire Fran\xE7aise, organisation professionnelle repr\xE9sentative de la profession bancaire. En mati\xE8re de produits d\u2019assurance, l\u2019Editeur agit en qualit\xE9 d\u2019interm\xE9diaire en assurance immatricul\xE9 \xE0 l\u2019ORIAS sous le n\xB007 002 199"), _react.default.createElement("p", null, "Les produits et services propos\xE9s sur ce site sont fournis par plusieurs promoteurs ou professionnels de l\u2019immobilier disposant des autorisations n\xE9cessaires pour exercer leur activit\xE9 et exer\xE7ant en conformit\xE9 avec les r\xE8gles professionnelles applicable \xE0 leur profession. Pour tout renseignement concernant ces fournisseurs vous pouvez adresser votre demande \xE0 : cecaz-b-cherchebienneuf@cecaz.caisse-epargne.fr."))));
};

LegalMentions.propTypes = {
  appEmailPublic: _propTypes.default.string.isRequired,
  appWWW: _propTypes.default.string.isRequired
};
var _default = LegalMentions;
exports.default = _default;