
const programmeValidation = {
	type: 'object',
	properties: {
		message: { type: 'string', maxLength: 1000 },
	},
	required: [
	],
};


export default programmeValidation;
