import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import { requestSearchAdminsAction } from '../../store/actions/admin';

import './selector.scss';


class AdminSelector extends React.Component {
	constructor(props) {
		super(props);
		props.searchAdmins().catch(console.error);

		this.getAdminOptions = this.getAdminOptions.bind(this);
	}

	getAdminOptions() {
		const { admins } = this.props;
		return admins.map(a => (
			{
				value: a.id,
				label: `${a.lastName} ${a.firstName}`,
			}));
	}

	render() {
		const { adminId, onChangeAdmin } = this.props;

		return (
			<Select
				className="Select-admin"
				joinValues
				value={adminId}
				name="adminId"
				placeholder="Admininistrateur"
				options={this.getAdminOptions()}
				onChange={onChangeAdmin}
			/>
		);
	}
}

AdminSelector.propTypes = {
	adminId: PropTypes.number,
	admins: PropTypes.arrayOf(PropTypes.shape({})),
	searchAdmins: PropTypes.func.isRequired,
	onChangeAdmin: PropTypes.func.isRequired,
};

AdminSelector.defaultProps = {
	adminId: null,
	admins: [],
};

const mapStateToProps = state => ({
	admins: state.admin.adminsSearched,
});

const mapDispatchToProps = dispatch => ({
	searchAdmins: () => dispatch(requestSearchAdminsAction({})),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AdminSelector);
