import React from 'react';
import PropTypes from 'prop-types';

import DeveloperEditListItem from './item';
import UserList from '../../../user/list/list';

const DeveloperEditList = (props) => {
	const {
		className,
		developers,
		lp,
	} = props;

	return (
		<UserList className={`DeveloperEditList ${className}`}>
			{
				developers.length
					&& developers.map(developer => (
						<li key={developer.id}>
							<DeveloperEditListItem
								developer={developer}
								lp={lp}
							/>
						</li>
					))
			}
		</UserList>
	);
};

DeveloperEditList.propTypes = {
	className: PropTypes.string,
	developers: PropTypes.arrayOf(PropTypes.shape({})),
	lp: PropTypes.bool,
};

DeveloperEditList.defaultProps = {
	className: '',
	developers: [],
	lp: false,
};

export default DeveloperEditList;
