"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _decode = _interopRequireDefault(require("@cecaz-immo/ui/dist/lib/decode"));

var _content = _interopRequireDefault(require("@cecaz-immo/ui/dist/components/page/content"));

var _resetPassword = _interopRequireDefault(require("../../components/auth/forms/reset-password"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var ResetPassword = function ResetPassword(_ref) {
  var developer = _ref.developer,
      history = _ref.history,
      lp = _ref.lp,
      match = _ref.match,
      rest = _objectWithoutProperties(_ref, ["developer", "history", "lp", "match"]);

  var name;
  var temporaryPassword;
  var username;
  var credentials = match.params.credentials;

  if (credentials) {
    var params = (0, _decode.default)(credentials);

    var _params = _slicedToArray(params, 3);

    username = _params[0];
    temporaryPassword = _params[1];
    name = _params[2];
  } else {
    var _match$params = match.params;
    username = _match$params.username;
    temporaryPassword = _match$params.temporaryPassword;
    name = _match$params.name;
  }

  return _react.default.createElement(_content.default, {
    className: "PageAuthResetPassword"
  }, _react.default.createElement("div", {
    className: "section last-section"
  }, _react.default.createElement("header", null, _react.default.createElement("h1", {
    className: "section-title"
  }, "Espace ", developer ? lp ? 'Partenaire' : 'Promoteur' : 'Administrateur')), _react.default.createElement("div", {
    className: "page-center"
  }, _react.default.createElement(_resetPassword.default, _extends({
    history: history,
    name: name,
    temporaryPassword: temporaryPassword,
    username: username
  }, rest)))));
};

ResetPassword.propTypes = {
  developer: _propTypes.default.bool,
  history: _propTypes.default.shape({}).isRequired,
  lp: _propTypes.default.bool
};
ResetPassword.defaultProps = {
  developer: false,
  lp: false
};
var _default = ResetPassword;
exports.default = _default;