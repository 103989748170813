"use strict";

require("@babel/polyfill");

require("react-app-polyfill/ie11");

require("isomorphic-fetch"); //fetch


global.isIEcancer = !!window.MSInputMethodContext && !!document.documentMode;
/* eslint-disable */
// flat

if (!Array.prototype.flat) {
  Object.defineProperties(Array.prototype, {
    flat: {
      configurable: true,
      value: function flat() {
        var depth = isNaN(arguments[0]) ? 1 : Number(arguments[0]);
        var stack = Array.prototype.slice.call(this);
        var result = [];

        while (depth && stack.length) {
          var next = stack.pop();

          if (Object(next) instanceof Array) {
            --depth;
            Array.prototype.push.apply(stack, next);
          } else {
            result.unshift(next);
          }
        }

        return result.concat(stack);
      },
      writable: true
    },
    flatMap: {
      configurable: true,
      value: function flatMap(callback) {
        return Array.prototype.map.apply(this, arguments).flat();
      },
      writable: true
    }
  });
} // isInteger


Number.isInteger = Number.isInteger || function isInteger(value) {
  // eslint-disable-next-line no-restricted-globals
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}; // includes String


if (!String.prototype.includes) {
  // eslint-disable-next-line no-extend-native
  String.prototype.includes = function includesPolyfill(search, start) {
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    }

    return this.indexOf(search, start) !== -1;
  };
} // includes Array


if (!Array.prototype.includes) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Array.prototype, 'includes', {
    value: function valuesFunc(searchElement, fromIndex) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this); // 2. Let len be ? ToLength(? Get(O, "length")).

      var len = o.length >>> 0; // 3. If len is 0, return false.

      if (len === 0) {
        return false;
      } // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)


      var n = fromIndex | 0; // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.

      var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x, y) {
        return x === y || typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y);
      } // 7. Repeat, while k < len


      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(searchElement, elementK) is true, return true.
        // c. Increase k by 1.
        if (sameValueZero(o[k], searchElement)) {
          return true;
        }

        k++;
      } // 8. Return false


      return false;
    }
  });
} // parseInt


if (Number.parseInt === undefined) {
  Number.parseInt = window.parseInt;
} // some


if (!Array.prototype.some) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.some = function arraySome(fun
  /*, thisArg*/
  ) {
    if (this == null) {
      throw new TypeError('Array.prototype.some called on null or undefined');
    }

    if (typeof fun !== 'function') {
      throw new TypeError();
    }

    var t = Object(this);
    var len = t.length >>> 0;
    var thisArg = arguments.length >= 2 ? arguments[1] : void 0;

    for (var i = 0; i < len; i++) {
      if (i in t && fun.call(thisArg, t[i], i, t)) {
        return true;
      }
    }

    return false;
  };
} // Object.values


Object.values = Object.values ? Object.values : function (obj) {
  var allowedTypes = ['[object String]', '[object Object]', '[object Array]', '[object Function]'];
  var objType = Object.prototype.toString.call(obj);

  if (obj === null || typeof obj === 'undefined') {
    throw new TypeError('Cannot convert undefined or null to object');
  } else if (!~allowedTypes.indexOf(objType)) {
    return [];
  } else {
    // if ES6 is supported
    if (Object.keys) {
      return Object.keys(obj).map(function (key) {
        return obj[key];
      });
    }

    var result = [];

    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        result.push(obj[prop]);
      }
    }

    return result;
  }
};