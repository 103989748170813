import { fetchWithSession } from '@cecaz-immo/app-developer/dist/lib/session';

const { REACT_APP_API_URL } = process.env;

export const SET_DEVELOPERS_SEARCHED_ACTION = 'SET_DEVELOPERS_SEARCHED_ACTION';

export const setDevelopersSearchedAction = developers => ({
	type: SET_DEVELOPERS_SEARCHED_ACTION,
	developers,
});

export const requestSearchDevelopersAction = data => (dispatch) => {
	let params = '';
	if (data) {
		params += '?';

		Object.keys(data).forEach((property) => {
			if (!data[property]) {
				return;
			}
			params += `${property}=${data[property]}&`;
		});
	}

	return fetchWithSession(
		`${REACT_APP_API_URL}/promoteur/rechercher${params}`,
		{
			headers: {
				'Cache-Control': 'no-cache',
			},
		},
	)
		.then(resp => resp.json())
		.then((result) => {
			dispatch(setDevelopersSearchedAction(result));
		});
};
