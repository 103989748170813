"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.password = void 0;
var password = {
  type: 'string',
  // eslint-disable-next-line quotes
  pattern: "(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$"
};
exports.password = password;
var _default = {};
exports.default = _default;