"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _item = _interopRequireDefault(require("./item"));

require("./list.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ProgrammeEditList = function ProgrammeEditList(props) {
  var className = props.className,
      displayEditButton = props.displayEditButton,
      programmes = props.programmes,
      status = props.status;
  return _react.default.createElement("ul", {
    className: "ProgrammeEditList ProgrammeList list-undecorated ".concat(className)
  }, programmes.length ? programmes.map(function (programme, index) {
    return (!status || status === programme.status) && _react.default.createElement("li", {
      key: programme.id
    }, _react.default.createElement(_item.default, {
      displayEditButton: displayEditButton,
      index: index,
      programme: programme
    }));
  }) : _react.default.createElement("li", {
    className: "no-result"
  }, "Aucun r\xE9sultat"));
};

ProgrammeEditList.propTypes = {
  className: _propTypes.default.string,
  displayEditButton: _propTypes.default.bool,
  programmes: _propTypes.default.arrayOf(_propTypes.default.shape({})),
  status: _propTypes.default.string
};
ProgrammeEditList.defaultProps = {
  className: '',
  displayEditButton: false,
  programmes: null,
  status: null
};
var _default = ProgrammeEditList;
exports.default = _default;