"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.boundsEqual = exports.areBoundsValid = void 0;
/*eslint-disable no-restricted-globals*/

var areBoundsValid = function areBoundsValid(bounds) {
  return bounds && bounds.south && !isNaN(bounds.south) && bounds.west && !isNaN(bounds.west) && bounds.north && !isNaN(bounds.north) && bounds.east && !isNaN(bounds.east);
};

exports.areBoundsValid = areBoundsValid;

var boundsEqual = function boundsEqual(bounds1, bounds2) {
  if (bounds1 === bounds2) {
    return true;
  }

  if (!bounds1 || !bounds2) {
    return false;
  }

  return bounds1.south === bounds2.south && bounds1.west === bounds2.west && bounds1.north === bounds2.north && bounds1.east === bounds2.east;
};
/*eslint-enable no-restricted-globals*/


exports.boundsEqual = boundsEqual;