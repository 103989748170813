"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOtherProgrammes = exports.getLastProgrammes = exports.getCurrentProgramme = exports.searchProgramme = exports.searchProgrammeWithFetch = exports.transformProgramme = exports.sortFilesFromProgramme = exports.calculatePricesAndRoomsProgramme = exports.GET_OTHER_PROGRAMMES_ACTION = exports.GET_LAST_PROGRAMMES_ACTION = exports.GET_CURRENT_PROGRAMME_ACTION = exports.SEARCH_PROGRAMME_RESULT_ACTION = exports.SEARCH_PROGRAMME_DEBUT_ACTION = void 0;

var _request = require("../../lib/request");

var REACT_APP_API_URL = process.env.REACT_APP_API_URL;
var SEARCH_PROGRAMME_DEBUT_ACTION = 'SEARCH_PROGRAMME_DEBUT_ACTION';
exports.SEARCH_PROGRAMME_DEBUT_ACTION = SEARCH_PROGRAMME_DEBUT_ACTION;
var SEARCH_PROGRAMME_RESULT_ACTION = 'SEARCH_PROGRAMME_RESULT_ACTION';
exports.SEARCH_PROGRAMME_RESULT_ACTION = SEARCH_PROGRAMME_RESULT_ACTION;
var GET_CURRENT_PROGRAMME_ACTION = 'GET_CURRENT_PROGRAMME_ACTION';
exports.GET_CURRENT_PROGRAMME_ACTION = GET_CURRENT_PROGRAMME_ACTION;
var GET_LAST_PROGRAMMES_ACTION = 'GET_LAST_PROGRAMMES_ACTION';
exports.GET_LAST_PROGRAMMES_ACTION = GET_LAST_PROGRAMMES_ACTION;
var GET_OTHER_PROGRAMMES_ACTION = 'GET_OTHER_PROGRAMMES_ACTION';
/**
	Calculate min/max of prices and rooms.
*/

exports.GET_OTHER_PROGRAMMES_ACTION = GET_OTHER_PROGRAMMES_ACTION;

var calculatePricesAndRoomsProgramme = function calculatePricesAndRoomsProgramme(programme) {
  if (!programme || !programme.lots || !programme.lots.length) return programme;
  programme.lots.forEach(function (lot, i) {
    if (i === 0) {
      programme.minPrice = lot.minPrice;
      programme.maxPrice = lot.maxPrice;
      programme.minRooms = lot.rooms;
      programme.maxRooms = lot.rooms;
      return;
    }

    if (lot.minPrice < programme.minPrice) programme.minPrice = lot.minPrice;
    if (lot.maxPrice > programme.maxPrice) programme.maxPrice = lot.maxPrice;
    if (lot.rooms < programme.minRooms) programme.minRooms = lot.rooms;
    if (lot.rooms > programme.maxRooms) programme.maxRooms = lot.rooms;
  });
  return programme;
};
/**
	Sort files of programme by attribute "order"
 */


exports.calculatePricesAndRoomsProgramme = calculatePricesAndRoomsProgramme;

var sortFilesFromProgramme = function sortFilesFromProgramme(programme) {
  if (!programme || !programme.files || !programme.files.length) {
    return;
  }

  programme.files.sort(function (a, b) {
    return a.order - b.order;
  });
};
/**
 	Transform programme
 */


exports.sortFilesFromProgramme = sortFilesFromProgramme;

var transformProgramme = function transformProgramme(programme) {
  calculatePricesAndRoomsProgramme(programme);
  sortFilesFromProgramme(programme);
}; // ---- SEARCH PROGRAMME


exports.transformProgramme = transformProgramme;

var searchProgrammeDebutAction = function searchProgrammeDebutAction() {
  return {
    type: SEARCH_PROGRAMME_DEBUT_ACTION
  };
};

var searchProgrammeResultAction = function searchProgrammeResultAction(programmes) {
  return {
    type: SEARCH_PROGRAMME_RESULT_ACTION,
    programmes: programmes
  };
};

var searchProgrammeWithFetch = function searchProgrammeWithFetch(search) {
  var fetchMethod = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : fetch;
  return function (dispatch) {
    dispatch(searchProgrammeDebutAction());
    var params = '';
    var data = {
      adminId: search.adminId,
      developerId: search.developerId,
      south: search.location && search.location.bounds.south,
      west: search.location && search.location.bounds.west,
      north: search.location && search.location.bounds.north,
      east: search.location && search.location.bounds.east,
      minRooms: search.minRooms,
      maxRooms: search.maxRooms,
      minPrice: search.minPrice,
      maxPrice: search.maxPrice,
      developer: search.developer,
      programme: search.programme,
      status: search.status
    };

    if (data) {
      params += '?';
      Object.keys(data).forEach(function (property) {
        if (!data[property]) {
          return;
        }

        params += "".concat(property, "=").concat(data[property], "&");
      });
    }

    return fetchMethod("".concat(REACT_APP_API_URL, "/rechercher").concat(params), {
      headers: {
        'Cache-Control': 'no-cache'
      }
    }).then(function (resp) {
      return resp.json();
    }).then(function (results) {
      results.forEach(function (p) {
        transformProgramme(p);
      });
      dispatch(searchProgrammeResultAction(results));
    }).catch(function () {
      dispatch(searchProgrammeResultAction([]));
    });
  };
};

exports.searchProgrammeWithFetch = searchProgrammeWithFetch;

var searchProgramme = function searchProgramme(search) {
  return searchProgrammeWithFetch(search);
}; // ---- GET CURRENT PROGRAMME


exports.searchProgramme = searchProgramme;

var getCurrentProgrammeAction = function getCurrentProgrammeAction(programme) {
  return {
    type: GET_CURRENT_PROGRAMME_ACTION,
    programme: programme
  };
};

var getCurrentProgramme = function getCurrentProgramme(uuid) {
  return function (dispatch) {
    return fetch("".concat(REACT_APP_API_URL, "/programme/").concat(uuid), {
      headers: {
        'Cache-Control': 'no-cache'
      }
    }).then(function (resp) {
      return resp.json();
    }).then(function (result) {
      transformProgramme(result);
      dispatch(getCurrentProgrammeAction(result));
    });
  };
}; // ----  SET LAST PROGRAMMES


exports.getCurrentProgramme = getCurrentProgramme;

var getLastProgrammesAction = function getLastProgrammesAction(programmes) {
  return {
    type: GET_LAST_PROGRAMMES_ACTION,
    programmes: programmes
  };
};

var getLastProgrammes = function getLastProgrammes() {
  return function (dispatch) {
    return fetch("".concat(REACT_APP_API_URL, "/programmes/derniers"), {
      headers: {
        'Cache-Control': 'no-cache'
      }
    }).then(function (resp) {
      return resp.json();
    }).then(function (results) {
      results.forEach(function (item) {
        transformProgramme(item);
      });
      dispatch(getLastProgrammesAction(results));
    });
  };
}; // ----  SET OTHER PROGRAMMES


exports.getLastProgrammes = getLastProgrammes;

var getOtherProgrammesAction = function getOtherProgrammesAction(programmes) {
  return {
    type: GET_OTHER_PROGRAMMES_ACTION,
    programmes: programmes
  };
};

var getOtherProgrammes = function getOtherProgrammes(uuid) {
  return function (dispatch) {
    return (0, _request.request)("".concat(REACT_APP_API_URL, "/programme/").concat(uuid, "/autres"), {
      headers: {
        'Cache-Control': 'no-cache'
      }
    }).then(function (resp) {
      return resp.json();
    }).then(function (results) {
      results.forEach(function (item) {
        transformProgramme(item);
      });
      dispatch(getOtherProgrammesAction(results));
    }).catch(_request.checkNotFoundError);
  };
};

exports.getOtherProgrammes = getOtherProgrammes;