import { SET_DEVELOPERS_SEARCHED_ACTION } from '../actions/developer';
import { EDIT_DEVELOPER_DELETE_DEVELOPER } from '../actions/edit-developer';

const developersSearched = (state = [], action) => {
	let developers = Object.assign([], state);
	switch (action.type) {
	case SET_DEVELOPERS_SEARCHED_ACTION:
		// eslint-disable-next-line prefer-destructuring
		developers = action.developers;
		break;
	case EDIT_DEVELOPER_DELETE_DEVELOPER:
		developers = state.filter(developer => developer.id !== action.developer.id);
		break;
	default:
		return state;
	}

	return developers;
};

const defaultState = { developersSearched: [] };
export default (state = defaultState, action) => {
	let newState;
	switch (action.type) {
	case SET_DEVELOPERS_SEARCHED_ACTION:
	case EDIT_DEVELOPER_DELETE_DEVELOPER:
		newState = Object.assign({}, state);
		newState.developersSearched = developersSearched(newState.developersSearched, action);
		return newState;
	default:
		return state;
	}
};
