"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var developerSchema = {
  type: 'object',
  properties: {
    firstName: {
      type: 'string',
      maxLength: 255
    },
    lastName: {
      type: 'string',
      maxLength: 255
    },
    tradingName: {
      type: 'string',
      maxLength: 45
    },
    phone: {
      type: 'string',
      minLength: 10
    },
    email: {
      type: 'string',
      format: 'email',
      maxLength: 255
    }
  },
  required: ['email', 'firstName', 'lastName']
};
var _default = developerSchema;
exports.default = _default;