"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _session = require("../actions/session");
/*
 * session is null until SET_SESSION or INVALIDATE_SESSION is called.
 * This features allows the session component to prevent redirects while
 * the session has not been verified yet. Once session is not null,
 * redirects to "connection" or "forbidden" pages can occur.
 */


var defaultState = null;
var invalidState = {
  attributes: null,
  roles: null,
  tokens: null,
  valid: false
};

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _session.SESSION_SET_SESSION_ACTION:
      return Object.assign({}, state, {
        valid: true
      }, action.session);

    case _session.SESSION_CLEAR_SESSION_ACTION:
      return defaultState;

    case _session.SESSION_INVALIDATE_SESSION_ACTION:
      return Object.assign({}, invalidState);

    default:
      return state;
  }
};

exports.default = _default;