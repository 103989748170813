import React from 'react';
import {
	HashRouter,
	Redirect,
	Route,
	Switch,
} from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import { connect } from '@cecaz-immo/app-developer/dist/lib/session';
import CookiesBanner from '@cecaz-immo/ui/dist/components/cookies/banner';
import Footer from '@cecaz-immo/app-client/dist/components/footer/footer';
import ScrollToTop from '@cecaz-immo/ui/dist/components/scroll-to-top/scroll-to-top';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from '@cecaz-immo/app-developer/dist/lib/roles';
import { RouteIfHasRole } from '@cecaz-immo/app-developer/dist/components/security/security';
import HeaderSession from '@cecaz-immo/app-developer/dist/components/header/session';
import RedirectClient from '@cecaz-immo/app-developer/dist/components/redirect/client';

import PageAuthSignIn from '@cecaz-immo/app-developer/dist/pages/auth/sign-in';
import PageAuthForgotPassword from '@cecaz-immo/app-developer/dist/pages/auth/forgot-password';
import PageAuthResetPassword from '@cecaz-immo/app-developer/dist/pages/auth/reset-password';
import PageForbidden from '@cecaz-immo/app-developer/dist/pages/forbidden';
import PageLegal from '@cecaz-immo/app-developer/dist/pages/legal';
import PageNotFound from '@cecaz-immo/app-client/dist/pages/404';


import NavAdmin from './components/nav/admin';

import PageAccount from './pages/account';
import PageAdminEdit from './pages/admin/edit';
import PageAdminSearch from './pages/admin/search';
import PageCookies from './pages/cookies';
import PageDeveloperEdit from './pages/developer/edit';
import PageDeveloperSearch from './pages/developer/search';
import PageExport from './pages/export';
import PageHelp from './pages/help';
import PageHome from './pages/home/home';
import PageProgramme from './pages/programme/programme';
import PageProgrammeSearch from './pages/programme/search';

import Reducers from './store/reducers';

const logger = createLogger({
	// predicate: (getState, action) => action.type !== '',
	collapsed: (getState, action) => action.type !== '',
});

let middleware = [thunkMiddleware];

if (process.env.NODE_ENV !== 'production') {
	middleware = [...middleware, logger];
}

const store = createStore(Reducers, applyMiddleware(...middleware));
connect(store);

const AdminPageAuthForgotPassword = props => <PageAuthForgotPassword {...props} />;
const AdminPageAuthResetPassword = props => <PageAuthResetPassword {...props} />;
const AdminPageAuthResetPasswordWithCode = props => (
	<AdminPageAuthResetPassword withCode {...props} />);
const AdminPageAuthSignIn = props => <PageAuthSignIn {...props} />;

const AppAdmin = () => (
	<CookiesProvider>
		<Provider store={store}>
			{/*<HashRouter basename={process.env.PUBLIC_URL}>*/}
			<HashRouter>
				<div className={`wrapper admin ${global.isIEcancer ? 'ie' : ''}`}>
					<Route component={ScrollToTop} />
					<CookiesBanner />
					<Route component={HeaderSession} />
					<Route component={NavAdmin} />

					<Switch>
						<Route
							path="/auth/signin"
							component={AdminPageAuthSignIn}
						/>
						<Route
							path="/auth/forgot-password/reset-password/:username"
							render={AdminPageAuthResetPasswordWithCode}
						/>
						{ /* redirect previous route (missing '/auth/') */ }
						<Route
							path="/forgot-password/reset-password/:username/:name"
							render={({ match }) => {
								const { username } = match.params;
								return (
									<Redirect to={`/auth/forgot-password/reset-password/${username}`} />
								);
							}}
						/>
						<Route
							path="/auth/forgot-password/"
							component={AdminPageAuthForgotPassword}
						/>
						<Route
							path="/auth/reset-password/:username/:temporaryPassword/:name"
							component={AdminPageAuthResetPassword}
						/>
						{ /* redirect previous route (missing '/auth/') */ }
						<Route
							path="/reset-password/:username/:temporaryPassword/:name"
							render={({ match }) => {
								const { username, temporaryPassword, name } = match.params;
								return (
									<Redirect to={`/auth/reset-password/${username}/${temporaryPassword}/${name}`} />
								);
							}}
						/>
						{ /* new route with encoded params in base64 */ }
						<Route
							path="/finalize/:credentials"
							component={AdminPageAuthResetPassword}
						/>
						<Route path="/forbidden" component={PageForbidden} />
						<Route path="/politique-cookies" component={PageCookies} />
						<Route path="/help" component={PageHelp} />
						<Route path="/mentions-legales" component={PageLegal} />

						{/* redirect to client */}
						<RedirectClient path="/loi-pinel" />
						<RedirectClient path="/pret-taux-zero" />
						<RouteIfHasRole
							path="/account"
							component={PageAccount}
							role={ROLE_ADMIN}
						/>
						<RouteIfHasRole
							path="/programme/search"
							component={PageProgrammeSearch}
							role={ROLE_ADMIN}
						/>
						<RouteIfHasRole
							path="/programme/:name/p/:uuid"
							component={PageProgramme}
							role={ROLE_ADMIN}
						/>
						<RouteIfHasRole
							path="/admin/edit/:id"
							component={PageAdminEdit}
							role={ROLE_SUPER_ADMIN}
						/>
						<RouteIfHasRole
							path="/admin/edit"
							component={PageAdminEdit}
							role={ROLE_SUPER_ADMIN}
						/>
						<RouteIfHasRole
							path="/admin"
							component={PageAdminSearch}
							role={ROLE_SUPER_ADMIN}
						/>
						<RouteIfHasRole
							path="/developer/edit/:id"
							component={PageDeveloperEdit}
							role={ROLE_ADMIN}
						/>
						<RouteIfHasRole
							path="/developer/edit"
							component={PageDeveloperEdit}
							role={ROLE_ADMIN}
						/>
						<RouteIfHasRole
							path="/developer"
							component={PageDeveloperSearch}
							role={ROLE_ADMIN}
						/>
						<RouteIfHasRole
							path="/export"
							component={PageExport}
							role={ROLE_SUPER_ADMIN}
						/>
						<RouteIfHasRole
							exact
							path="/"
							component={PageHome}
							role={ROLE_ADMIN}
						/>
						<Route path="*" component={PageNotFound} />
					</Switch>
					<Footer />
				</div>
			</HashRouter>
		</Provider>
	</CookiesProvider>
);

export default AppAdmin;
