"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

require("./actions.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _default = function _default(_ref) {
  var children = _ref.children;
  return _react.default.createElement("header", {
    className: "section Actions"
  }, _react.default.createElement("div", {
    className: "page-center"
  }, children));
};

exports.default = _default;