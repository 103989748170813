"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasOneOfRoles = exports.hasSessionOneOfRoles = exports.changePassword = exports.confirmResetPassword = exports.forgotPassword = exports.completeNewPasswordChallenge = exports.signOut = exports.signIn = exports.fetchWithSession = exports.connect = void 0;

var _request = require("@cecaz-immo/app-client/dist/lib/request");

var _session = require("../store/actions/session");

var cognito = _interopRequireWildcard(require("./cognito"));

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

var REACT_APP_API_URL = process.env.REACT_APP_API_URL;

var dispatch = function dispatch() {
  throw new Error('store is not connected');
};

var formatSession = function formatSession(session) {
  var cognitoSession = session.cognitoSession;
  return {
    attributes: Object.assign({}, session.cognitoUserAttributes),
    roles: session.decodedIdToken['cognito:roles'],
    tokens: {
      accessToken: cognitoSession.accessToken.jwtToken,
      idToken: cognitoSession.idToken.jwtToken,
      refreshToken: cognitoSession.refreshToken.token
    }
  };
};

var clearSession = function clearSession() {
  return dispatch((0, _session.clearSessionAction)());
};

var invalidateSession = function invalidateSession() {
  return dispatch((0, _session.invalidateSessionAction)());
};

var setSession = function setSession() {
  return cognito.getFullSession().then(function (session) {
    var formattedSession = formatSession(session);
    return dispatch((0, _session.setSessionAction)(formattedSession));
  });
};

var connect = function connect(store) {
  // console.log('connect');
  dispatch = store.dispatch.bind(store);
  cognito.getSession().then(function () {
    return setSession();
  }).catch(function () {
    invalidateSession();
  });
};

exports.connect = connect;

var fetchWithSession = function fetchWithSession(url, options) {
  return cognito.getSession().catch(function () {
    invalidateSession();
    return null;
  }).then(function (session) {
    if (session) {
      var headers = Object.assign({}, options && options.headers || {}, {
        Authorization: session.getIdToken().getJwtToken()
      });
      options = Object.assign({}, options, {
        headers: headers
      });
    }

    return (0, _request.request)(url, options);
  });
};

exports.fetchWithSession = fetchWithSession;

var signIn = function signIn(username, password) {
  return cognito.signIn(username, password).then(function () {
    return setSession();
  });
};

exports.signIn = signIn;

var signOut = function signOut() {
  return cognito.signOut().then(function () {
    return clearSession();
  });
};

exports.signOut = signOut;

var completeNewPasswordChallenge = function completeNewPasswordChallenge(username, password, newPassword) {
  return cognito.completeNewPasswordChallenge(username, password, newPassword).then(function () {
    return setSession();
  });
};

exports.completeNewPasswordChallenge = completeNewPasswordChallenge;

var forgotPassword = function forgotPassword(username, email, userType) {
  username = (username || '').toLowerCase();
  userType = userType === 'developer' ? 'developer' : 'admin';
  return fetchWithSession("".concat(REACT_APP_API_URL, "/").concat(userType, "/password/reset"), {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({
      username: username,
      email: email
    })
  }).then(function (resp) {
    if (!resp.ok) {
      return Promise.reject(resp);
    }

    return resp;
  }).then(function (res) {
    return res.json();
  }).catch(function (res) {
    return res.json().then(function (data) {
      return Promise.reject(new Error(), {
        code: data.error
      });
    });
  });
};

exports.forgotPassword = forgotPassword;

var confirmResetPassword = function confirmResetPassword(username, password, code) {
  return cognito.confirmResetPassword(username, password, code);
};

exports.confirmResetPassword = confirmResetPassword;

var changePassword = function changePassword(username, oldPassword, newPassword) {
  return cognito.changePassword(username, oldPassword, newPassword);
};

exports.changePassword = changePassword;

var hasSessionOneOfRoles = function hasSessionOneOfRoles(session, roles) {
  if (!session || !session.roles) return false;
  roles = [].concat(roles);
  var sessionRoles = session.roles;
  var matchingRoles = sessionRoles.filter(function (role) {
    return roles.includes(role);
  });
  return matchingRoles.length ? matchingRoles : false;
};

exports.hasSessionOneOfRoles = hasSessionOneOfRoles;

var hasOneOfRoles = function hasOneOfRoles(roles) {
  return cognito.getSession().then(function (session) {
    return hasSessionOneOfRoles(session, roles);
  });
};

exports.hasOneOfRoles = hasOneOfRoles;