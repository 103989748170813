"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RangeRoomsDisplay = exports.RangePriceDisplay = exports.RangeAreaDisplay = exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var formatNumber = function formatNumber(num) {
  /*eslint-disable-next-line no-restricted-globals*/
  if (!num || isNaN(num)) return num;
  return num.toLocaleString(undefined, {
    maximumFractionDigits: 2
  });
};

var RangeDisplay = function RangeDisplay(props) {
  var className = props.className,
      room = props.room;
  var min = props.min,
      max = props.max,
      textBefore = props.textBefore,
      textFrom = props.textFrom,
      textBetween = props.textBetween,
      textAfter = props.textAfter;
  textBefore = textBefore || 'De ';
  textFrom = textFrom || 'À partir de ';
  textBetween = textBetween || ' à ';
  textAfter = textAfter || '';
  min = formatNumber(min);
  max = formatNumber(max);
  var content = '';

  if (min && !max) {
    content = textFrom + min + textAfter;
  } else if (!min && max) {
    content = max + textAfter;
  } else if (min && max) {
    content = textBefore + min + textBetween + max + textAfter;
  }

  if (min === max && room) {
    content = min + textAfter;
  }

  return _react.default.createElement("span", {
    className: "RangeDisplay ".concat(className || '')
  }, content);
};

RangeDisplay.propTypes = {
  className: _propTypes.default.string,
  min: _propTypes.default.number,
  max: _propTypes.default.number,
  room: _propTypes.default.bool,
  textBefore: _propTypes.default.string,
  textFrom: _propTypes.default.string,
  textBetween: _propTypes.default.string,
  textAfter: _propTypes.default.string
};
RangeDisplay.defaultProps = {
  className: '',
  min: 0,
  max: 0,
  room: false,
  textBefore: '',
  textFrom: '',
  textBetween: '',
  textAfter: ''
};

var RangeAreaDisplay = function RangeAreaDisplay(props) {
  return _react.default.createElement(RangeDisplay, _extends({
    textBefore: "De ",
    textBetween: " \xE0 ",
    textAfter: " m\xB2"
  }, props));
};

exports.RangeAreaDisplay = RangeAreaDisplay;

var RangePriceDisplay = function RangePriceDisplay(props) {
  return _react.default.createElement(RangeDisplay, _extends({
    textBetween: " \u20AC \xE0 ",
    textAfter: " \u20AC"
  }, props));
};

exports.RangePriceDisplay = RangePriceDisplay;

var RangeRoomsDisplay = function RangeRoomsDisplay(props) {
  return _react.default.createElement(RangeDisplay, _extends({
    room: true,
    textBefore: "Du ",
    textBetween: " au ",
    textAfter: " pi\xE8ces"
  }, props));
};

exports.RangeRoomsDisplay = RangeRoomsDisplay;
var _default = RangeDisplay;
exports.default = _default;