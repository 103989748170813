import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import Actions from '@cecaz-immo/app-developer/dist/components/actions/actions';
import PageContent from '@cecaz-immo/ui/dist/components/page/content';

import AdminEditList from '../../components/admin/edit/list/list';
import { requestSearchAdminsAction } from '../../store/actions/admin';

class Search extends React.Component {
	constructor(props) {
		super(props);

		const { searchAdmins } = props;

		searchAdmins().catch(console.error);
	}

	render() {
		const { admins } = this.props;
		return (
			<PageContent className="PageAdminSearch">
				<Actions>
					<Link to="/admin/edit" className="btn btn-add">
						Créer un administrateur <i className="fa fa-plus-circle" />
					</Link>
				</Actions>
				<section className="section last-section">
					<header>
						<h1 className="section-title">Administrateurs</h1>
					</header>
					<div className="page-center">
						<AdminEditList admins={admins} />
					</div>
				</section>
			</PageContent>
		);
	}
}

Search.propTypes = {
	admins: PropTypes.arrayOf(PropTypes.shape({})),
	searchAdmins: PropTypes.func.isRequired,
};

Search.defaultProps = {
	admins: [],
};

const mapStateToProps = state => ({
	admins: state.admin.adminsSearched,
});

const mapDispatchToProps = dispatch => ({
	searchAdmins: params => dispatch(requestSearchAdminsAction(params)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Search);
