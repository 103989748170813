"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ROLE_SUPER_ADMIN = exports.ROLE_DEVELOPER = exports.ROLE_ADMIN = void 0;
var ROLE_ADMIN = process.env.REACT_APP_ROLE_ADMIN;
exports.ROLE_ADMIN = ROLE_ADMIN;
var ROLE_DEVELOPER = process.env.REACT_APP_ROLE_DEVELOPER;
exports.ROLE_DEVELOPER = ROLE_DEVELOPER;
var ROLE_SUPER_ADMIN = process.env.REACT_APP_ROLE_SUPER_ADMIN;
exports.ROLE_SUPER_ADMIN = ROLE_SUPER_ADMIN;