"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactRouterDom = require("react-router-dom");

var _rangeDisplay = require("@cecaz-immo/app-client/dist/components/range/range-display");

var _slider = _interopRequireDefault(require("@cecaz-immo/app-client/dist/components/slider/slider"));

var _file = require("@cecaz-immo/app-client/dist/lib/file");

var _date = require("@cecaz-immo/ui/dist/lib/date");

var _roles = require("../../lib/roles");

var _security = require("../security/security");

require("./programme-list-item.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function getQuarterString(number, small) {
  var dimin;

  switch (number) {
    case 1:
      dimin = 'er';
      break;

    case 2:
      dimin = 'nd';
      break;

    default:
      dimin = small ? 'e' : 'ème';
  }

  var str = small ? 'T' : 'trimestre';
  return "".concat(number).concat(dimin, " ").concat(str);
}

var ProgrammeListItem = function ProgrammeListItem(props) {
  var Actions = props.Actions,
      className = props.className,
      editionDetail = props.editionDetail,
      Overlay = props.Overlay,
      programme = props.programme;
  return _react.default.createElement("article", {
    className: "ProgrammeListItem ".concat(className || '')
  }, editionDetail && _react.default.createElement("div", {
    className: "info"
  }, _react.default.createElement("p", {
    className: "ProgrammeListItem-field"
  }, _react.default.createElement("span", {
    className: "ProgrammeListItem-field-label"
  }, "Date de cr\xE9ation"), _react.default.createElement("em", {
    className: "ProgrammeListItem-field-value"
  }, (0, _date.toInputDateTime)(programme.createAt))), _react.default.createElement("p", {
    className: "ProgrammeListItem-field"
  }, _react.default.createElement("span", {
    className: "ProgrammeListItem-field-label"
  }, "Date de la derni\xE8re modification"), _react.default.createElement("em", {
    className: "ProgrammeListItem-field-value"
  }, (0, _date.toInputDateTime)(programme.updateAt))), programme.publishAt && _react.default.createElement("p", {
    className: "ProgrammeListItem-field"
  }, _react.default.createElement("span", {
    className: "ProgrammeListItem-field-label"
  }, "Date de publication"), _react.default.createElement("em", {
    className: "ProgrammeListItem-field-value"
  }, (0, _date.toInputDateTime)(programme.publishAt)))), _react.default.createElement(_slider.default, {
    className: "ProgrammeListItem-img"
  }, programme.files && programme.files.map(function (file) {
    return _react.default.createElement("div", {
      className: "slider-centered-img",
      key: file.name
    }, _react.default.createElement("img", {
      src: file.preview || (0, _file.getFileUrl)(file)
    }));
  })), _react.default.createElement("div", {
    className: "ProgrammeListItem-properties"
  }, _react.default.createElement("p", {
    className: "ProgrammeListItem-field"
  }, _react.default.createElement("span", {
    className: "ProgrammeListItem-field-label"
  }, "Programme"), _react.default.createElement("em", {
    className: "ProgrammeListItem-field-value ProgrammeListItem-name"
  }, programme.name)), _react.default.createElement("p", {
    className: "ProgrammeListItem-field"
  }, _react.default.createElement("span", {
    className: "ProgrammeListItem-field-label"
  }, "Localisation"), _react.default.createElement("em", {
    className: "ProgrammeListItem-field-value ProgrammeListItem-address"
  }, programme.district)), _react.default.createElement("p", {
    className: "ProgrammeListItem-field"
  }, _react.default.createElement("span", {
    className: "ProgrammeListItem-field-label"
  }, "Prix"), _react.default.createElement("em", {
    className: "ProgrammeListItem-field-value"
  }, _react.default.createElement(_rangeDisplay.RangePriceDisplay, {
    min: programme.minPrice,
    max: programme.maxPrice
  }))), _react.default.createElement("p", {
    className: "ProgrammeListItem-field"
  }, _react.default.createElement("span", {
    className: "ProgrammeListItem-field-label"
  }, "Pi\xE8ces"), _react.default.createElement("em", {
    className: "ProgrammeListItem-field-value"
  }, _react.default.createElement(_rangeDisplay.RangeRoomsDisplay, {
    min: programme.minRooms,
    max: programme.maxRooms
  }))), _react.default.createElement("p", {
    className: "ProgrammeListItem-field"
  }, _react.default.createElement("span", {
    className: "ProgrammeListItem-field-label"
  }, "Livraison"), _react.default.createElement("em", {
    className: "ProgrammeListItem-field-value"
  }, getQuarterString(programme.quarterDelivery), ' ', programme.yearDelivery))), _react.default.createElement("div", {
    className: "ProgrammeListItem-bottom-right-corner"
  }, _react.default.createElement("em", {
    className: "visible-cols-2 delivery"
  }, getQuarterString(programme.quarterDelivery, true), ' ', programme.yearDelivery), _react.default.createElement(_reactRouterDom.Link, {
    to: "/programme/".concat(programme.name, "/p/").concat(programme.uuid),
    className: "btn btn-red ".concat(Actions ? 'btn-icon' : '')
  }, !Actions && _react.default.createElement("span", null, "Voir le programme"), _react.default.createElement("i", {
    className: "ic ic-voir"
  })), Actions), _react.default.createElement(_security.WithRole, {
    role: _roles.ROLE_ADMIN
  }, _react.default.createElement("div", {
    className: "info info-admin"
  }, _react.default.createElement("p", {
    className: "ProgrammeListItem-field"
  }, _react.default.createElement("span", {
    className: "ProgrammeListItem-field-label"
  }, "Version"), _react.default.createElement("em", {
    className: "ProgrammeListItem-field-value"
  }, programme.version)), _react.default.createElement(_security.WithRole, {
    role: _roles.ROLE_SUPER_ADMIN
  }, _react.default.createElement("p", {
    className: "ProgrammeListItem-field"
  }, _react.default.createElement("span", {
    className: "ProgrammeListItem-field-label"
  }, "Admininistrateur"), _react.default.createElement("em", {
    className: "ProgrammeListItem-field-value"
  }, programme.developer && programme.developer.admin && "".concat(programme.developer.admin.lastName, " ").concat(programme.developer.admin.firstName)))), _react.default.createElement("p", {
    className: "ProgrammeListItem-field"
  }, _react.default.createElement("span", {
    className: "ProgrammeListItem-field-label"
  }, "Promoteur"), _react.default.createElement("em", {
    className: "ProgrammeListItem-field-value"
  }, programme.developer && "".concat(programme.developer.lastName, " ").concat(programme.developer.firstName))))), Overlay);
};

ProgrammeListItem.propTypes = {
  Actions: _propTypes.default.node,
  className: _propTypes.default.string,
  editionDetail: _propTypes.default.bool,
  Overlay: _propTypes.default.node,
  programme: _propTypes.default.shape({}).isRequired
};
ProgrammeListItem.defaultProps = {
  Actions: null,
  className: '',
  editionDetail: false,
  Overlay: null
};
var _default = ProgrammeListItem;
exports.default = _default;