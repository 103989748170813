import React from 'react';
import PropTypes from 'prop-types';

import AdminEditListItem from './item';
import UserList from '../../../user/list/list';

const AdminEditList = (props) => {
	const {
		className,
		admins,
	} = props;

	return (
		<UserList className={`AdminEditList ${className}`}>
			{
				admins.length
					&& admins.map(admin => (
						<li key={admin.id}>
							<AdminEditListItem
								admin={admin}
							/>
						</li>
					))
			}
		</UserList>
	);
};

AdminEditList.propTypes = {
	className: PropTypes.string,
	admins: PropTypes.arrayOf(PropTypes.shape({})),
};

AdminEditList.defaultProps = {
	className: '',
	admins: [],
};

export default AdminEditList;
