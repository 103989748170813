"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // const defaultMessage = () => 'Ce champ est invalide.';

var pattern = function pattern() {
  return 'Le format est invalide.';
};

var format = function format() {
  return 'Le format est invalide.';
};

var required = function required() {
  return 'Ce champ est requis.';
};

var maxLength = function maxLength() {
  return 'Le contenu est trop long.';
};

var minLength = function minLength() {
  return 'Le contenu est trop court.';
};

var _default = {
  // defaultMessage,
  format: format,
  maxLength: maxLength,
  minLength: minLength,
  pattern: pattern,
  required: required
};
exports.default = _default;