"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

require("./Page.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Page = function Page(_ref) {
  var children = _ref.children,
      className = _ref.className;
  return _react.default.createElement("div", {
    className: "Page ".concat(className)
  }, children);
};

Page.propTypes = {
  children: _propTypes.default.node.isRequired,
  className: _propTypes.default.string
};
Page.defaultProps = {
  className: ''
};
var _default = Page;
exports.default = _default;