import download from 'downloadjs';
import React from 'react';

import { fetchWithSession } from '@cecaz-immo/app-developer/dist/lib/session';
import { formatTodayDate } from '@cecaz-immo/ui/dist/lib/date';
import PageContent from '@cecaz-immo/ui/dist/components/page/content';

import './export.scss';

const { REACT_APP_API_URL } = process.env;


class Export extends React.Component {
	// eslint-disable-next-line class-methods-use-this
	handleClick(entity) {
		fetchWithSession(
			`${REACT_APP_API_URL}/export/${entity}/`,
			{
				headers: {
					'Cache-Control': 'no-cache',
				},
			},
		).then(resp => (
			resp.blob()
		).then(blob => (
			download(blob, `${formatTodayDate()}-cherchebienneuf-${entity}.csv`, 'data:text/csv;charset=UTF-8,')
		)));
	}

	render() {
		return (
			<PageContent className="PageExport">
				<section className="section page-center">
					<header>
						<h1 className="section-title">Données de contact</h1>
					</header>

					<p>
						Télécharger
						ci-dessous les données relatives aux formulaires de contact, au format CSV.
					</p>
					<div className="download-section">
						<button type="button" className="btn" onClick={this.handleClick.bind(this, 'contact')}>
							Demandes liées aux <strong>financements</strong>
						</button>
						<button type="button" className="btn" onClick={this.handleClick.bind(this, 'contact-programme')}>
							Demandes liées à un <strong>programme</strong>
						</button>
					</div>
				</section>
			</PageContent>
		);
	}
}

export default Export;
