import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import Nav from '@cecaz-immo/ui/dist/components/nav/nav';
import NavItem from '@cecaz-immo/ui/dist/components/nav/nav-item';
import { ROLE_SUPER_ADMIN } from '@cecaz-immo/app-developer/dist/lib/roles';
import { WithRole } from '@cecaz-immo/app-developer/dist/components/security/security';

const NavAdmin = (props) => {
	const { location, session } = props;
	const current = location.pathname.split('/')[1];
	return (
			<>
				{
					session && session.valid
					&& (
						<Nav>
							<NavItem current={current} id="" label="Accueil" link="/" />
							<WithRole role={ROLE_SUPER_ADMIN}>
								<NavItem current={current} id="admin" label="Administrateurs" link="/admin" />
							</WithRole>
							<NavItem current={current} id="developer" label="Promoteurs" link="/developer/search" />
							<NavItem current={current} id="programme" label="Programmes" link="/programme/search" />
							<NavItem current={current} id="account" label="Mon compte" link="/account" />
							<WithRole role={ROLE_SUPER_ADMIN}>
								<NavItem current={current} id="export" label="Export" link="/export" />
							</WithRole>
							<NavItem current={current} id="help" label="Aide" link="/help" />
						</Nav>
					)
				}
			</>);
};
NavAdmin.propTypes = {
	location: PropTypes.shape({}).isRequired,
	session: PropTypes.shape({}),
};

NavAdmin.defaultProps = {
	session: {},
};
const mapStateToProps = state => ({
	session: state.session,
});

export default connect(mapStateToProps)(NavAdmin);
