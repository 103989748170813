"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _programme = require("../actions/programme");

var programmes = [];
var programmesSearched = [];
var currentProgramme = {};
var lastProgrammes = [];
var otherProgrammes = [];
var defaultState = {
  programmes: programmes,
  programmesSearched: programmesSearched,
  currentProgramme: currentProgramme,
  lastProgrammes: lastProgrammes,
  otherProgrammes: otherProgrammes,
  searching: false
};

var programme = function programme() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _programme.SEARCH_PROGRAMME_DEBUT_ACTION:
      return Object.assign({}, state, {
        searching: true
      });

    case _programme.SEARCH_PROGRAMME_RESULT_ACTION:
      return Object.assign({}, state, {
        programmesSearched: action.programmes,
        searching: false
      });

    case _programme.GET_CURRENT_PROGRAMME_ACTION:
      return Object.assign({}, state, {
        currentProgramme: action.programme
      });

    case _programme.GET_LAST_PROGRAMMES_ACTION:
      return Object.assign({}, state, {
        lastProgrammes: action.programmes
      });

    case _programme.GET_OTHER_PROGRAMMES_ACTION:
      return Object.assign({}, state, {
        otherProgrammes: action.programmes
      });

    default:
      return state;
  }
};

var _default = programme;
exports.default = _default;