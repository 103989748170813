const programmeSearchSchema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		adminId: { type: 'number' },
		developerId: { type: 'number' },
		developer: { type: 'string' },
		location: {
			additionalProperties: false,
			type: 'object',
			properties: {
				label: { type: 'string' },
				type: { type: 'string' },
				value: {
					oneOf: [
						{
							type: 'object',
							properties: {
								additionalProperties: false,
								lat: { type: 'number' },
								lng: { type: 'number' },
							},
						},
						{
							type: 'string',
						},
					],
				},
			},
		},
		programme: { type: 'string' },
		status: { type: 'string' },
	},
	required: [
	],
};


export default programmeSearchSchema;
