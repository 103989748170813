import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import PageContent from '@cecaz-immo/ui/dist/components/page/content';
import ProgrammeListEdit from '@cecaz-immo/app-developer/dist/components/programme/edit/list/list';

import {
	requestGetCurrentAdminAction,
	requestGetCurrentAdminWaitingProgrammesAction,
	requestGetCurrentAdminLastDevelopersAction,
} from '../../store/actions/admin';

import './home.scss';

class AdminIndex extends React.Component {
	constructor(props) {
		super(props);

		const {
			getCurrentAdmin,
			getCurrentAdminLastDevelopers,
			getCurrentAdminWaitingProgrammes,
		} = props;

		getCurrentAdmin()
			.then(() => Promise.all([
				getCurrentAdminLastDevelopers(),
				getCurrentAdminWaitingProgrammes(),
			]))
			.catch((err) => {
				console.log('err ', err);
			});
	}

	render() {
		const { admin } = this.props;
		const waitingProgrammes = admin.waitingProgrammes || [];
		const lastDevelopers = admin.lastDevelopers || [];

		return (
			<PageContent className="PageAdmin">
				<section className="section page-center">
					<h3 className="section-title">Programmes en attente de validation</h3>
					<ProgrammeListEdit displayEditButton={false} programmes={waitingProgrammes} />
				</section>
				<section className="section page-center last-section">
					<h3 className="section-title">Les derniers promoteurs ajoutés</h3>
					<ul className="list-undecorated Home-LastDevelopersList">
						{
							lastDevelopers.map(item => (
								<Link key={item.id} to={`/developer/edit/${item.id}`}>
									{`${item.firstName} ${item.lastName}`}
								</Link>))
						}
					</ul>
				</section>
			</PageContent>
		);
	}
}

const mapStateToProps = state => ({
	admin: state.admin.currentAdmin,
});

const mapDispatchToProps = dispatch => ({
	getCurrentAdmin: () => dispatch(requestGetCurrentAdminAction()),
	getCurrentAdminLastDevelopers: () => dispatch(requestGetCurrentAdminLastDevelopersAction()),
	getCurrentAdminWaitingProgrammes: () => dispatch(requestGetCurrentAdminWaitingProgrammesAction()),
});

AdminIndex.propTypes = {
	admin: PropTypes.shape({}),
	getCurrentAdmin: PropTypes.func.isRequired,
	getCurrentAdminLastDevelopers: PropTypes.func.isRequired,
	getCurrentAdminWaitingProgrammes: PropTypes.func.isRequired,
};

AdminIndex.defaultProps = {
	admin: {},
};

const AdminIndexPage = connect(
	mapStateToProps,
	mapDispatchToProps,
)(AdminIndex);

export default AdminIndexPage;
