"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactHelmet = _interopRequireDefault(require("react-helmet"));

var _reactRouterDom = require("react-router-dom");

var _react = _interopRequireDefault(require("react"));

var _Page = _interopRequireDefault(require("../components/page/Page"));

require("./404.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/*eslint-disable react/no-unescaped-entities*/


var PageNotFound = function PageNotFound() {
  return _react.default.createElement(_Page.default, {
    className: "PageNotFound"
  }, _react.default.createElement(_reactHelmet.default, null, _react.default.createElement("title", null, "cherchebienneuf.fr \u2013 404")), _react.default.createElement("section", {
    className: "PageContact-container page-center"
  }, _react.default.createElement("section", null, _react.default.createElement("p", null, "La page que vous avez demand\xE9e n'est pas disponible ou n'existe plus."), _react.default.createElement("p", null, "Veuillez nous excuser pour cet incident."), _react.default.createElement(_reactRouterDom.Link, {
    className: "btn btn-error",
    to: "/"
  }, "Retour sur la page d'accueil"))));
};

var _default = PageNotFound;
exports.default = _default;