import PropTypes from 'prop-types';
import React from 'react';


import AlertMessage from '@cecaz-immo/app-developer/dist/components/alert-message/alert-message';
import FieldAdmin from '@cecaz-immo/ui/dist/components/form/field/admin';
import FormAdmin from '@cecaz-immo/ui/dist/components/form/admin/admin';
import FormButton from '@cecaz-immo/ui/dist/components/form/button/button';
import FormGroup from '@cecaz-immo/ui/dist/components/form/group/group';

import adminEditSchema from '../../../schemas/admin-edit';

class AdminEdit extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			alertMessage: {
				title: '',
				message: '',
				display: false,
				success: false,
			},
			loading: false,
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		const el = e.target;
		const { updateAdmin } = this.props;

		updateAdmin({
			[el.name]: el.value,
		});
	}

	onSubmit(e) {
		e.preventDefault();
		const { myAccount } = this.props;
		this.setState({ loading: true });

		const { saveAdmin } = this.props;
		const entity = myAccount ? 'Votre compte' : 'L`administrateur';
		saveAdmin().then(() => {
			this.setState({
				loading: false,
				alertMessage: {
					display: true,
					title: 'Succès',
					message: `${entity} a été modifié avec succès`,
					success: true,
				},
			});
		}).catch(() => {
			this.setState({
				loading: false,
				alertMessage: {
					display: true,
					title: 'Erreur',
					message: 'Une erreur technique est survenue, merci de réessayer ultérieurement',
					success: false,
				},
			});
		});
	}

	render() {
		const { admin } = this.props;
		const {
			alertMessage,
			loading,
		} = this.state;

		return (
			<FormAdmin
				onSubmit={this.onSubmit}
				schema={adminEditSchema}
				data={admin}
			>
				<FormGroup>
					<label>Prénom</label>
					<FieldAdmin
						component="input"
						name="firstName"
						type="text"
						placeholder="Prénom"
						value={admin.firstName || ''}
						onChange={this.onChange}
					/>
				</FormGroup>
				<FormGroup>
					<label>Nom</label>
					<FieldAdmin
						component="input"
						name="lastName"
						type="text"
						placeholder="Nom"
						value={admin.lastName || ''}
						onChange={this.onChange}

					/>
				</FormGroup>
				<FormGroup>
					<label>Téléphone</label>
					<FieldAdmin
						component="input"
						name="phone"
						type="tel"
						placeholder="Téléphone"
						value={admin.phone || ''}
						onChange={this.onChange}
						errorMessages={{
							minLength: () => 'Le numéro est non valide',
						}}
					/>
				</FormGroup>
				<FormGroup>
					<label>Email</label>
					<FieldAdmin
						component="input"
						disabled={!!admin.id}
						name="email"
						type="tel"
						placeholder="Email"
						value={admin.email || ''}
						onChange={this.onChange}

					/>
				</FormGroup>
				<FormGroup>
					<FormButton loading={loading} type="submit">Valider</FormButton>
				</FormGroup>
				{
					alertMessage.display
					&& (
						<AlertMessage
							display={alertMessage.display}
							title={alertMessage.title}
							message={alertMessage.message}
							success={alertMessage.success}
						/>)
				}
			</FormAdmin>
		);
	}
}

AdminEdit.propTypes = {
	admin: PropTypes.shape({
		id: PropTypes.number,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		phone: PropTypes.string,
	}),
	myAccount: PropTypes.bool,
	saveAdmin: PropTypes.func.isRequired,
	updateAdmin: PropTypes.func.isRequired,
};

AdminEdit.defaultProps = {
	admin: {},
	myAccount: false,
};

export default AdminEdit;
