import classnames from 'classnames';
import React from 'react';
import Context from '../Form/Context';

var IfInvalid = function IfInvalid(props) {
  var className = props.className,
      fields = props.fields;
  return React.createElement(Context.Consumer, null, function (form) {
    var classes = classnames('Sjf_IfInvalid', className, {
      isSubmitted: form.isSubmitted,
      isTouched: form.isFieldTouched(fields)
    });
    return form.isFieldInvalid(fields) && React.createElement("div", {
      className: classes
    }, props.children);
  });
};

IfInvalid.defaultProps = {
  className: ''
};
export default IfInvalid;