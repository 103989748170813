"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

var _file = require("../../lib/file");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var SliderThumbnail = function SliderThumbnail(props) {
  var file = props.file,
      _onClick = props.onClick;
  var type = file.type;
  var url = (0, _file.getFileUrl)(file);

  if (type === 'video/mp4') {
    return _react.default.createElement("video", {
      className: "SliderThumbnail",
      src: url,
      onClick: function onClick() {
        _onClick();
      }
    });
  }

  return _react.default.createElement("img", {
    alt: "Miniature du slider de pr\xE9sentation du programme",
    className: "SliderThumbnail",
    src: url,
    onClick: function onClick() {
      _onClick();
    }
  });
};

SliderThumbnail.propTypes = {
  file: _propTypes.default.shape({}).isRequired,
  onClick: _propTypes.default.func.isRequired
};
var _default = SliderThumbnail;
exports.default = _default;