"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

require("./nav.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Nav = function Nav(props) {
  var children = props.children,
      className = props.className;
  return _react.default.createElement("nav", {
    className: "Nav ".concat(className)
  }, _react.default.createElement("ul", {
    className: "list-undecorated"
  }, children));
};

Nav.propTypes = {
  children: _propTypes.default.node,
  className: _propTypes.default.string
};
Nav.defaultProps = {
  children: null,
  className: ''
};
var _default = Nav;
exports.default = _default;