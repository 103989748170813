import React from 'react';
import PropTypes from 'prop-types';

import './list.scss';

const UserList = (props) => {
	const {
		className,
		children,
	} = props;

	return (
		<ul className={`UserList list-undecorated ${className}`}>
			{
				children || <li className="no-result">Aucun résultat</li>
			}
		</ul>
	);
};

UserList.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

UserList.defaultProps = {
	children: null,
	className: '',
};

export default UserList;
