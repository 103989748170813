import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ModalConfirm from '@cecaz-immo/app-developer/dist/components/modal/confirm/confirm';

import './item.scss';

class UserListItem extends React.Component {
	constructor(props) {
		super(props);

		this.onCancel = this.onCancel.bind(this);
		this.onClickDelete = this.onClickDelete.bind(this);
		this.onConfirm = this.onConfirm.bind(this);

		this.state = {
			confirmModalIsOpen: false,
		};
	}

	onClickDelete(e) {
		e.stopPropagation();
		this.openModal();
	}

	onCancel() {
		this.closeModal();
	}

	onConfirm() {
		const { onDeleteConfirm } = this.props;
		onDeleteConfirm();
		this.closeModal();
	}

	closeModal() {
		this.setState({ confirmModalIsOpen: false });
	}

	openModal() {
		this.setState({
			confirmModalIsOpen: true,
		});
	}

	render() {
		const {
			children,
			className,
			editLink,
			confirmDeleteMessage,
		} = this.props;
		const { confirmModalIsOpen } = this.state;

		return (
			<>
				<article className={`UserListItem ${className}`}>
					{children}
					<div className="UserListItem-corner UserListItem-bottom-right-corner">
						{
							editLink
							&& (
								<Link
									to={editLink}
									className="btn btn-icon btn-red"
								>
									<i className="fa fa-edit" />
								</Link>
							)
						}
						<button type="button" className="btn btn-icon btn-red" onClick={this.onClickDelete}>
							<i className="fa fa-trash" />
						</button>
					</div>
				</article>
				{
					confirmDeleteMessage
					&& (
						<ModalConfirm
							isOpen={confirmModalIsOpen}
							message={confirmDeleteMessage}
							onCancel={this.onCancel}
							onConfirm={this.onConfirm}
						/>
					)
				}
			</>
		);
	}
}

UserListItem.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	confirmDeleteMessage: PropTypes.node,
	editLink: PropTypes.string,
	onDeleteConfirm: PropTypes.func.isRequired,
};

UserListItem.defaultProps = {
	children: null,
	className: '',
	confirmDeleteMessage: null,
	editLink: '',
};

export default UserListItem;
