import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import {
	locationToGeometryBounds,
	LOCATION_TYPE_POSITION,
	LOCATION_TYPE_CITY,
	LOCATION_TYPE_POSTAL_CODE,
} from '@cecaz-immo/app-client/dist/lib/location';
import ProgrammeListEdit from '@cecaz-immo/app-developer/dist/components/programme/edit/list/list';
import PageContent from '@cecaz-immo/ui/dist/components/page/content';

import { ROLE_SUPER_ADMIN } from '@cecaz-immo/app-developer/dist/lib/roles';
import ProgrammeSearchBar from '../../components/programme/search-bar';


import { searchProgrammeBySession } from '../../store/actions/programme';

import searchProgrammeSchema from '../../schemas/programme-search';


class SearchProgramme extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: {
				adminId: null,
				developerId: null,
				developer: '',
				location: null,
				programme: '',
				status: '',
			},
			loading: false,
		};

		props.searchProgramme(this.state);

		this.onChangeAdmin = this.onChangeAdmin.bind(this);
		this.onChangeDeveloper = this.onChangeDeveloper.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
		this.onChangeLocation = this.onChangeLocation.bind(this);
		this.onChangeStatus = this.onChangeStatus.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChangeAdmin(admin) {
		const { data } = this.state;
		this.setState({
			data: { ...data, adminId: (admin && admin.value) || null },
		});
	}

	onChangeDeveloper(developer) {
		const { data } = this.state;
		this.setState({
			data: { ...data, developerId: (developer && developer.value) || null },
		});
	}

	onChangeStatus(status) {
		const { data } = this.state;
		this.setState({
			data: { ...data, status: (status && status.value) || '' },
		});
	}

	onChangeLocation(location) {
		const { data } = this.state;

		this.setState({
			data: { ...data, location },
		});
	}

	onChangeInput(e) {
		const { target } = e;

		this.setState(({ data }) => ({
			data: {
				...data,
				[target.name]: target.value,
			},
		}));
	}

	onSubmit(e) {
		e.preventDefault();

		this.setState({ loading: true });

		const { searchProgramme } = this.props;
		const { data } = this.state;
		const {
			adminId,
			developerId,
			developer,
			location,
			programme,
			status,
		} = data;

		const promise = new Promise((resolve, reject) => {
			const dataToSend = {
				adminId,
				developerId,
				status,
				developer,
				programme,
			};

			if (location) {
				dataToSend.location = Object.assign({}, location);

				switch (location.type) {
				case LOCATION_TYPE_POSITION:
					dataToSend.location.bounds = {
						south: location.value.lat - 0.01,
						west: location.value.lng - 0.01,
						north: location.value.lat + 0.01,
						east: location.value.lng + 0.01,
					};
					resolve(dataToSend);
					break;
				case LOCATION_TYPE_CITY:
				case LOCATION_TYPE_POSTAL_CODE:
					locationToGeometryBounds(location.value)
						.then((bounds) => {
							dataToSend.location.bounds = {
								south: bounds.southwest.lat,
								west: bounds.southwest.lng,
								north: bounds.northeast.lat,
								east: bounds.northeast.lng,
							};
							resolve(dataToSend);
						})
						.catch(reject);
					break;
				default:
					reject(new Error('bad location type'));
				}
			} else {
				resolve(dataToSend);
			}
		});

		promise
			.then(searchProgramme)
			.catch((err) => {
				this.setState({ loading: false });
				throw err;
			})
			.then((dataToSend) => {
				this.setState({ loading: false });
				return dataToSend;
			});
	}

	render() {
		const {
			lp,
			programmes,
		} = this.props;

		const {
			loading,
		} = this.state;

		const { data } = this.state;

		return (
			<PageContent className="PageProgrammeSearch">
				<section className="section page-center">
					<header>
						<h1 className="section-title">Rechercher un {lp ? 'bien' : 'programme'}</h1>
					</header>
					<ProgrammeSearchBar
						data={data}
						loading={loading}
						onChangeAdmin={this.onChangeAdmin}
						onChangeDeveloper={this.onChangeDeveloper}
						onChangeInput={this.onChangeInput}
						onChangeLocation={this.onChangeLocation}
						onChangeStatus={this.onChangeStatus}
						onSubmit={this.onSubmit}
						roleSuperAdmin={ROLE_SUPER_ADMIN}
						schema={searchProgrammeSchema}
					/>
				</section>
				<section className="section page-center last-section ">
					<span>Nombre de programmes : {programmes.length}</span>
					<ProgrammeListEdit displayEditButton={false} programmes={programmes} />
				</section>
			</PageContent>
		);
	}
}


SearchProgramme.propTypes = {
	lp: PropTypes.bool,
	history: PropTypes.shape({ push: PropTypes.func }),
	programmes: PropTypes.arrayOf(PropTypes.shape({})),
	searchProgramme: PropTypes.func.isRequired,
};

SearchProgramme.defaultProps = {
	history: {},
	lp: false,
	programmes: [],
};

const mapStateToProps = state => ({
	programmes: state.programme.programmesSearched,
});

const mapDispatchToProps = dispatch => ({
	searchProgramme: params => dispatch(searchProgrammeBySession(params)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SearchProgramme);
