"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

require("./table.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var LegalTable = function LegalTable(_ref) {
  var children = _ref.children;
  return _react.default.createElement("table", {
    className: "LegalTable table table-bordered"
  }, _react.default.createElement("tbody", null, children));
};

LegalTable.propTypes = {
  children: _propTypes.default.node
};
LegalTable.defaultProps = {
  children: null
};
var _default = LegalTable;
exports.default = _default;