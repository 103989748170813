import _objectSpread from "@babel/runtime/helpers/esm/objectSpread";
import classnames from 'classnames';
import React from 'react';
import Context from '../Form/Context';
import getErrorMessage from './getErrorMessage';

var FieldError = function FieldError(props) {
  var children = props.children,
      className = props.className,
      errorMessages = props.errorMessages,
      name = props.name;
  return React.createElement(Context.Consumer, null, function (form) {
    var fieldErrors = form.getFieldErrors(name);

    var fieldErrorMessages = _objectSpread({}, form.errorMessages, errorMessages);

    var classes = classnames('Sjf_FieldError', className, {
      isSubmitted: form.isSubmitted,
      isTouched: form.isFieldTouched(name)
    });
    return fieldErrors.length ? React.createElement("div", {
      className: classes
    }, children || getErrorMessage(fieldErrors[0], fieldErrorMessages)) : null;
  });
};

FieldError.defaultProps = {
  children: null,
  errorMessages: null,
  className: ''
};
export default FieldError;