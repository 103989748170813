import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import PageContent from '@cecaz-immo/ui/dist/components/page/content';
import UserEditPassword from '@cecaz-immo/app-developer/dist/components/user/edit/password';

import AdminEdit from '../components/admin/edit/edit';
import {
	requestGetEditAdminFromCurrentUserAction,
	requestSaveEditAdminAction,
	updateEditAdminAction,
} from '../store/actions/edit-admin';

class Account extends React.Component {
	constructor(props) {
		super(props);

		const { getAdmin } = props;

		getAdmin()
			.catch((err) => { console.error(err); });
	}

	render() {
		const {
			admin,
			saveAdmin,
			updateAdmin,
		} = this.props;

		return (
			<PageContent className="PageAccount">
				<section className="section page-center">
					<h3 className="section-title">Modifier mes informations</h3>
					<AdminEdit
						admin={admin}
						myAccount
						saveAdmin={saveAdmin}
						updateAdmin={updateAdmin}
					/>
				</section>
				<section className="section page-center last-section">
					<h3 className="section-title">Modifier mon mot de passe</h3>
					<UserEditPassword user={admin} />
				</section>
			</PageContent>
		);
	}
}

Account.propTypes = {
	admin: PropTypes.shape({
		id: PropTypes.number,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		phone: PropTypes.string,
	}),
	getAdmin: PropTypes.func.isRequired,
	saveAdmin: PropTypes.func.isRequired,
	updateAdmin: PropTypes.func.isRequired,
};

Account.defaultProps = {
	admin: {},
};

const mapStateToProps = state => ({
	admin: state.editAdmin,
});

const mapDispatchToProps = dispatch => ({
	getAdmin: () => dispatch(requestGetEditAdminFromCurrentUserAction()),
	saveAdmin: () => dispatch(requestSaveEditAdminAction()),
	updateAdmin: admin => dispatch(updateEditAdminAction(admin)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Account);
