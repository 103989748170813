"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

require("./article.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var LegalArticle = function LegalArticle(_ref) {
  var children = _ref.children;
  return _react.default.createElement("article", {
    className: "LegalArticle"
  }, children);
};

LegalArticle.propTypes = {
  children: _propTypes.default.node
};
LegalArticle.defaultProps = {
  children: null
};
var _default = LegalArticle;
exports.default = _default;