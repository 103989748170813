"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRouterDom = require("react-router-dom");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var REACT_APP_CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

var _default = function _default(_ref) {
  var path = _ref.path;
  return _react.default.createElement(_reactRouterDom.Route, {
    path: path,
    component: function component() {
      window.location.replace("".concat(REACT_APP_CLIENT_URL).concat(path));
      return null;
    }
  });
};

exports.default = _default;