// import password from '@cecaz-immo/app-developer/dist/schemas/types';

const editAdmin = {
	type: 'object',
	properties: {
		firstName: { type: 'string', maxLength: 255 },
		lastName: { type: 'string', maxLength: 255 },
		phone: { type: 'string', maxLength: 255 },
		email: { type: 'string', format: 'email' },
	},
	required: [
		'email', 'firstName', 'lastName',
	],
};


export default editAdmin;
