"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var Button = function Button(_ref) {
  var children = _ref.children,
      className = _ref.className,
      loading = _ref.loading,
      type = _ref.type,
      rest = _objectWithoutProperties(_ref, ["children", "className", "loading", "type"]);

  return (// eslint-disable-next-line react/button-has-type
    _react.default.createElement("button", _extends({
      className: "FormButton btn btn-red ".concat(className),
      disabled: loading,
      type: type
    }, rest), loading ? _react.default.createElement("i", {
      className: "fa fa-spinner spinner"
    }) : children)
  );
};

Button.propTypes = {
  children: _propTypes.default.node.isRequired,
  className: _propTypes.default.string,
  loading: _propTypes.default.bool,
  type: _propTypes.default.string
};
Button.defaultProps = {
  className: '',
  loading: false,
  type: 'button'
};
var _default = Button;
exports.default = _default;