"use strict";

var toInputDate = function toInputDate(date, opt) {
  if (!date) return date; // IE11 welcome to hell
  // https://stackoverflow.com/questions/36225603/internet-explorer-returning-wrong-length-of-string

  return "".concat(new Date(date).toLocaleDateString('fr-FR', opt).replace(/[^ -~]/g, ''));
};

var toInputDateTime = function toInputDateTime(date) {
  return toInputDate(date, {
    hour: '2-digit',
    minute: '2-digit'
  });
};

var toISO = function toISO(date) {
  if (!date) return date;
  var arr = date.split('/');
  var englishDate = "".concat(arr[1], "/").concat(arr[0], "/").concat(arr[2]);
  return new Date(englishDate).toISOString();
};

var formatTodayDate = function formatTodayDate() {
  var myDate = new Date();
  var date = myDate.getDate();
  var month = myDate.getMonth();
  var year = myDate.getFullYear();

  function pad(n) {
    return n < 10 ? "0".concat(n) : n;
  }

  return "".concat(pad(date), "-").concat(pad(month + 1), "-").concat(year);
};

exports.formatTodayDate = formatTodayDate;
exports.toInputDate = toInputDate;
exports.toInputDateTime = toInputDateTime;
exports.toISO = toISO;