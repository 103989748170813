import React from 'react';

import Help from '@cecaz-immo/app-developer/dist/components/help/help';
import PageContent from '@cecaz-immo/ui/dist/components/page/content';

export default () => (
	<>
		<PageContent className="PageHelp">
			<section className="page-center section last-section">
				<Help />
			</section>
		</PageContent>
	</>
);
