import '@cecaz-immo/ui/dist/lib/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-select/dist/react-select.min.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-simple-jsonschema-form/dist/react-simple-jsonschema-form.css';

import '@cecaz-immo/ui/dist/styles/fonts/fonts.scss';
import '@cecaz-immo/ui/dist/styles/base/base.scss';

import './styles/main.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// eslint-disable-next-line no-console
console.info(`ℹ️ v${process.env.REACT_APP_VERSION}`);
