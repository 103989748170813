import { fetchWithSession } from '@cecaz-immo/app-developer/dist/lib/session';

const { REACT_APP_API_URL } = process.env;

export const EDIT_DEVELOPER_DELETE_DEVELOPER = 'EDIT_DEVELOPER_DELETE_DEVELOPER';
export const EDIT_DEVELOPER_RESET_DEVELOPER = 'EDIT_DEVELOPER_RESET_DEVELOPER';
export const EDIT_DEVELOPER_UPDATE_DEVELOPER = 'EDIT_DEVELOPER_UPDATE_DEVELOPER';

export const deleteEditDeveloperAction = developer => ({
	type: EDIT_DEVELOPER_DELETE_DEVELOPER,
	developer,
});

export const resetEditDeveloperAction = () => ({
	type: EDIT_DEVELOPER_RESET_DEVELOPER,
});

export const updateEditDeveloperAction = developer => ({
	type: EDIT_DEVELOPER_UPDATE_DEVELOPER,
	developer,
});

export const requestGetEditDeveloperAction = id => dispatch => fetchWithSession(`${REACT_APP_API_URL}/promoteur/${id}`)
	.then((res) => {
		if (!res.ok) {
			return Promise.reject(res);
		}
		return res;
	})
	.then(res => res.json())
	.then((developer) => {
		dispatch(updateEditDeveloperAction(developer));
	});

export const requestSaveEditDeveloperAction = () => (dispatch, getState) => {
	const developer = getState().editDeveloper;
	let method = '';
	let request = '';

	if (developer.id) {
		method = 'PUT';
		request = `${REACT_APP_API_URL}/promoteur/${developer.id}`;
	} else {
		method = 'POST';
		request = `${REACT_APP_API_URL}/promoteur/`;
	}

	return fetchWithSession(request, {
		headers: {
			'Content-Type': 'application/json',
		},
		method,
		body: JSON.stringify(developer),
	})
		.then((res) => {
			if (!res.ok) {
				return Promise.reject(res);
			}
			return res;
		})
		.then(res => res.json())
		.catch(res => res.json().then((err) => { throw err; }));
};

export const requestDeleteEditDeveloperAction = developer => dispatch => fetchWithSession(`${REACT_APP_API_URL}/promoteur/${developer.id}`, {
	headers: {
		'Content-Type': 'application/json',
	},
	method: 'DELETE',
})
	.then((res) => {
		if (!res.ok) {
			return Promise.reject(res);
		}
		return res;
	})
	.then(() => dispatch(deleteEditDeveloperAction(developer)));
