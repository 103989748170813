"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

require("./section.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var LegalSection = function LegalSection(_ref) {
  var children = _ref.children;
  return _react.default.createElement("section", {
    className: "LegalSection"
  }, children);
};

LegalSection.propTypes = {
  children: _propTypes.default.node
};
LegalSection.defaultProps = {
  children: null
};
var _default = LegalSection;
exports.default = _default;