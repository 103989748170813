"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

var _reactRouterDom = require("react-router-dom");

require("./footer.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Footer = function Footer(_ref) {
  var lp = _ref.lp;
  return _react.default.createElement("footer", {
    className: "Footer"
  }, _react.default.createElement("div", {
    className: "Footer-links section"
  }, _react.default.createElement("div", {
    className: "page-center"
  }, _react.default.createElement("div", {
    className: "Footer-links-cols"
  }, _react.default.createElement("ul", {
    className: "Footer-links-list list-undecorated"
  }, _react.default.createElement("li", null, _react.default.createElement("a", {
    href: "https://www.caisse-epargne.fr/cote-d-azur"
  }, "Caisse d'Epargne C\xF4te d'Azur")), _react.default.createElement("li", null, _react.default.createElement("a", {
    href: "https://luxuryproperties-caisse-epargne.fr"
  }, "Luxury Properties"))), _react.default.createElement("ul", {
    className: "Footer-links-list list-undecorated"
  }, _react.default.createElement("li", null, _react.default.createElement(_reactRouterDom.Link, {
    to: "/conditions-generales-utilisation"
  }, "Conditions g\xE9n\xE9rales d'utilisation")), _react.default.createElement("li", null, _react.default.createElement(_reactRouterDom.Link, {
    to: "/mentions-legales"
  }, "Mentions l\xE9gales")), _react.default.createElement("li", null, _react.default.createElement(_reactRouterDom.Link, {
    to: "/politique-cookies"
  }, "Politiques des cookies"))), _react.default.createElement("ul", {
    className: "Footer-links-list list-undecorated"
  }, _react.default.createElement("li", null, _react.default.createElement("a", {
    href: "https://www.caisse-epargne.fr/cote-d-azur/particuliers/emprunter/dossier-immobilier"
  }, "Nos conseils pour acheter")), !lp && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("li", null, _react.default.createElement(_reactRouterDom.Link, {
    to: "/pret-taux-zero"
  }, "Le Pr\xEAt \xE0 Taux Z\xE9ro")), _react.default.createElement("li", null, _react.default.createElement(_reactRouterDom.Link, {
    to: "/loi-pinel"
  }, "La Loi Pinel")))), _react.default.createElement("div", {
    className: "Footer-social-networks"
  }, _react.default.createElement("p", null, "Retrouvez-nous et suivez-nous"), _react.default.createElement("ul", {
    className: "Footer-social-networks-list list-undecorated"
  }, _react.default.createElement("li", null, _react.default.createElement("a", {
    href: "https://www.facebook.com/caissedepargnecotedazur"
  }, _react.default.createElement("i", {
    className: "ic ic-facebook",
    "aria-hidden": "true"
  }))), _react.default.createElement("li", null, _react.default.createElement("a", {
    href: "https://www.linkedin.com/company/3107203/"
  }, _react.default.createElement("i", {
    className: "ic ic-linkedin",
    "aria-hidden": "true"
  }))), _react.default.createElement("li", null, _react.default.createElement("a", {
    href: "https://twitter.com/CE_CotedAzur"
  }, _react.default.createElement("i", {
    className: "ic ic-twitter",
    "aria-hidden": "true"
  })))))))), _react.default.createElement("div", {
    className: "Footer-legal section"
  }, _react.default.createElement("p", {
    className: "page-center"
  }, "\xA9 Caisse d'Epargne C\xF4te d'Azur. Tous droits r\xE9serv\xE9s.")));
};

Footer.propTypes = {
  lp: _propTypes.default.bool
};
Footer.defaultProps = {
  lp: false
};
var _default = Footer;
exports.default = _default;