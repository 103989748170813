import { fetchWithSession } from '@cecaz-immo/app-developer/dist/lib/session';

const { REACT_APP_API_URL } = process.env;

export const EDIT_ADMIN_UPDATE_ADMIN = 'EDIT_ADMIN_UPDATE_ADMIN';
export const EDIT_ADMIN_DELETE_ADMIN = 'EDIT_ADMIN_DELETE_ADMIN';
export const EDIT_ADMIN_RESET_ADMIN = 'EDIT_ADMIN_RESET_ADMIN';

export const resetEditAdminAction = () => ({
	type: EDIT_ADMIN_RESET_ADMIN,
});

export const updateEditAdminAction = admin => ({
	type: EDIT_ADMIN_UPDATE_ADMIN,
	admin,
});

export const deleteEditAdminAction = admin => ({
	type: EDIT_ADMIN_DELETE_ADMIN,
	admin,
});

export const requestGetEditAdminAction = id => dispatch => fetchWithSession(`${REACT_APP_API_URL}/admin/${id}`)
	.then((res) => {
		if (!res.ok) {
			return Promise.reject(res);
		}
		return res;
	})
	.then(res => res.json())
	.then((admin) => {
		dispatch(updateEditAdminAction(admin));
	});

export const requestGetEditAdminFromCurrentUserAction = () => dispatch => fetchWithSession(`${REACT_APP_API_URL}/admin?fetchType=LAZY`)
	.then((res) => {
		if (!res.ok) {
			return Promise.reject(res);
		}
		return res;
	})
	.then(res => res.json())
	.then((admin) => {
		dispatch(updateEditAdminAction(admin));
	});

export const requestSaveEditAdminAction = () => (dispatch, getState) => {
	const admin = getState().editAdmin;
	let method = '';
	let request = '';

	if (admin.id) {
		method = 'PUT';
		request = `${REACT_APP_API_URL}/admin/${admin.id}`;
	} else {
		method = 'POST';
		request = `${REACT_APP_API_URL}/admin/`;
	}

	return fetchWithSession(request, {
		headers: {
			'Content-Type': 'application/json',
		},
		method,
		body: JSON.stringify(admin),
	})
		.then((res) => {
			if (!res.ok) {
				return Promise.reject(res);
			}
			return res;
		})
		.then(res => res.json())
		.catch(res => res.json().then((err) => { throw err; }));
};

export const requestDeleteEditAdminAction = admin => dispatch => fetchWithSession(`${REACT_APP_API_URL}/admin/${admin.id}`, {
	headers: {
		'Content-Type': 'application/json',
	},
	method: 'DELETE',
})
	.then((res) => {
		if (!res.ok) {
			return Promise.reject(res);
		}
		return res;
	})
	.then(() => dispatch(deleteEditAdminAction(admin)));
