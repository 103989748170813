"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _mentions = _interopRequireDefault(require("@cecaz-immo/app-client/dist/components/legal/mentions"));

var _content = _interopRequireDefault(require("@cecaz-immo/ui/dist/components/page/content"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _process$env = process.env,
    REACT_APP_EMAIL_PUBLIC = _process$env.REACT_APP_EMAIL_PUBLIC,
    REACT_APP_WWW = _process$env.REACT_APP_WWW;

var PageLegal = function PageLegal() {
  return _react.default.createElement(_content.default, {
    className: "PageLegal"
  }, _react.default.createElement("section", {
    className: "page-center section last-section"
  }, _react.default.createElement(_mentions.default, {
    appLegalEmail: REACT_APP_EMAIL_PUBLIC,
    appWWW: REACT_APP_WWW
  })));
};

var _default = PageLegal;
exports.default = _default;