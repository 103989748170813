"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

require("./content.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var PageContent = function PageContent(_ref) {
  var children = _ref.children,
      className = _ref.className;
  return _react.default.createElement("section", {
    className: "PageContent ".concat(className)
  }, children);
};

PageContent.propTypes = {
  children: _propTypes.default.node,
  className: _propTypes.default.string
};
PageContent.defaultProps = {
  className: '',
  children: null
};
var _default = PageContent;
exports.default = _default;