"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

require("./group.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Group = function Group(_ref) {
  var className = _ref.className,
      children = _ref.children;
  return _react.default.createElement("div", {
    className: "FormGroup ".concat(className)
  }, children);
};

Group.propTypes = {
  children: _propTypes.default.node,
  className: _propTypes.default.string
};
Group.defaultProps = {
  children: null,
  className: ''
};
var _default = Group;
exports.default = _default;