import { FINISHED } from '@cecaz-immo/app-developer/dist/lib/status';
import { EDIT_PROGRAMME_DELETE_ACTION } from '@cecaz-immo/app-developer/dist/store/actions/edit-programme';

import {
	SET_ADMINS_SEARCHED_ACTION,
	SET_CURRENT_ADMIN_ACTION,
	UPDATE_CURRENT_ADMIN_LAST_DEVELOPERS,
	UPDATE_CURRENT_ADMIN_WAITING_PROGRAMMES,
} from '../actions/admin';
import { EDIT_ADMIN_DELETE_ADMIN } from '../actions/edit-admin';

const adminsSearched = (state = [], action) => {
	let admins = Object.assign([], state);

	switch (action.type) {
	case SET_ADMINS_SEARCHED_ACTION:
		// eslint-disable-next-line prefer-destructuring
		admins = action.admins;
		break;
	case EDIT_ADMIN_DELETE_ADMIN:
		admins = state.filter(admin => admin.id !== action.admin.id);
		break;
	default:
		return state;
	}

	return admins;
};

const waitingProgrammes = (state = [], action) => {
	let newState = Object.assign([], state);

	switch (action.type) {
	case UPDATE_CURRENT_ADMIN_WAITING_PROGRAMMES:
		newState = action.programmes;
		break;
	case EDIT_PROGRAMME_DELETE_ACTION:
		newState = state.map((programme) => {
			if (programme.uuid !== action.programme.uuid) {
				return programme;
			}

			return {
				...programme,
				status: FINISHED,
			};
		});
		break;
	default:
		return state;
	}

	return newState;
};

const currentAdmin = (state = {}, action) => {
	let newState = Object.assign({}, state);
	switch (action.type) {
	case SET_CURRENT_ADMIN_ACTION:
		newState = action.admin;
		break;
	case UPDATE_CURRENT_ADMIN_WAITING_PROGRAMMES:
	case EDIT_PROGRAMME_DELETE_ACTION:
		newState.waitingProgrammes = waitingProgrammes(state.waitingProgrammes, action);
		break;
	case UPDATE_CURRENT_ADMIN_LAST_DEVELOPERS:
		newState.lastDevelopers = action.developers;
		break;
	default:
		return state;
	}

	return newState;
};

const defaultState = {
	currentAdmin: {},
	adminsSearched: [],
};

export default (state = defaultState, action) => {
	let newState;
	switch (action.type) {
	case SET_CURRENT_ADMIN_ACTION:
	case UPDATE_CURRENT_ADMIN_WAITING_PROGRAMMES:
	case UPDATE_CURRENT_ADMIN_LAST_DEVELOPERS:
	case EDIT_PROGRAMME_DELETE_ACTION:
		newState = Object.assign({}, state);
		newState.currentAdmin = currentAdmin(state.currentAdmin, action);
		return newState;
	case SET_ADMINS_SEARCHED_ACTION:
	case EDIT_ADMIN_DELETE_ADMIN:
		newState = Object.assign({}, state);
		newState.adminsSearched = adminsSearched(state.adminsSearched, action);
		return newState;
	default:
		return state;
	}
};
