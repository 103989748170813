"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RouteIfHasRole = exports.WithRole = exports.withRole = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

var _reactRouterDom = require("react-router-dom");

var _session = require("../../lib/session");

var _session2 = _interopRequireDefault(require("./session"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var AuthorizationSetter = function AuthorizationSetter(props) {
  var Component = props.Component,
      render = props.render,
      rest = _objectWithoutProperties(props, ["Component", "render"]);

  var role = rest.role,
      session = rest.session;
  var authorized = (0, _session.hasSessionOneOfRoles)(session, role);
  var newProps = Object.assign({
    authorized: authorized
  }, rest);
  if (Component) return _react.default.createElement(Component, newProps);
  if (render) return render(newProps);
  return null;
};

AuthorizationSetter.propTypes = {
  Component: _propTypes.default.oneOfType([_propTypes.default.string, _propTypes.default.func]),
  render: _propTypes.default.func,
  role: _propTypes.default.string.isRequired,
  session: _propTypes.default.shape({}).isRequired
};
AuthorizationSetter.defaultProps = {
  Component: null,
  render: null
};

var getAuthorizationSetter = function getAuthorizationSetter(role, Component, render) {
  return function (props) {
    return _react.default.createElement(AuthorizationSetter, _extends({
      Component: Component,
      render: render,
      role: role
    }, props));
  };
};

var withRole = function withRole(role) {
  return function (Component) {
    var SetAuthorized = getAuthorizationSetter(role, Component);
    return function (props) {
      return _react.default.createElement(_session2.default, _extends({
        Component: SetAuthorized
      }, props));
    };
  };
};

exports.withRole = withRole;

var WithRole = function WithRole(p) {
  var checkAuthorization = function checkAuthorization(props) {
    var session = props.session;
    var authorized = (0, _session.hasSessionOneOfRoles)(session, p.role);

    if (authorized.length) {
      return p.children;
    }

    return null;
  };

  return _react.default.createElement(_session2.default, _extends({
    render: checkAuthorization
  }, p));
};

exports.WithRole = WithRole;

var RouteIfHasRole = function RouteIfHasRole(p) {
  var notAuthorizedRedirection = p.notAuthorizedRedirection || '/forbidden';
  var notConnectedRedirection = p.notConnectedRedirection || '/auth/signin';
  var component = p.component,
      role = p.role;
  var Component = component;
  var routeProps = Object.assign({}, p);
  delete routeProps.component;

  var renderComponentIfAllowedOrRedirect = function renderComponentIfAllowedOrRedirect(props) {
    var authorized = props.authorized,
        session = props.session;
    if (authorized) return _react.default.createElement(Component, props);
    if (!session || !session.valid) return _react.default.createElement(_reactRouterDom.Redirect, {
      to: notConnectedRedirection
    });
    return _react.default.createElement(_reactRouterDom.Redirect, {
      to: notAuthorizedRedirection
    });
  };

  var SetAuthorized = getAuthorizationSetter(role, null, renderComponentIfAllowedOrRedirect);

  var render = function render(props) {
    return _react.default.createElement(_session2.default, _extends({
      Component: SetAuthorized
    }, props));
  };

  return _react.default.createElement(_reactRouterDom.Route, _extends({}, routeProps, {
    render: render
  }));
};

exports.RouteIfHasRole = RouteIfHasRole;