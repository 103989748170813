"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findLocations = findLocations;
exports.locationToGeometryBounds = locationToGeometryBounds;
exports.getCurrentPosition = getCurrentPosition;
exports.geolocationAvailable = exports.LOCATION_TYPE_BOUNDS = exports.LOCATION_TYPE_POSITION = exports.LOCATION_TYPE_POSTAL_CODE = exports.LOCATION_TYPE_CITY = exports.LOCATION_TYPE_DEP = void 0;
var REACT_APP_API_URL = process.env.REACT_APP_API_URL;
var LOCATION_TYPE_DEP = 'LOCATION_TYPE_DEP';
exports.LOCATION_TYPE_DEP = LOCATION_TYPE_DEP;
var LOCATION_TYPE_CITY = 'LOCATION_TYPE_CITY';
exports.LOCATION_TYPE_CITY = LOCATION_TYPE_CITY;
var LOCATION_TYPE_POSTAL_CODE = 'LOCATION_TYPE_POSTAL_CODE';
exports.LOCATION_TYPE_POSTAL_CODE = LOCATION_TYPE_POSTAL_CODE;
var LOCATION_TYPE_POSITION = 'LOCATION_TYPE_POSITION';
exports.LOCATION_TYPE_POSITION = LOCATION_TYPE_POSITION;
var LOCATION_TYPE_BOUNDS = 'LOCATION_TYPE_BOUNDS';
exports.LOCATION_TYPE_BOUNDS = LOCATION_TYPE_BOUNDS;

function findLocations(input) {
  if (!input) {
    return Promise.reject();
  }

  var url = "".concat(REACT_APP_API_URL, "/locations/").concat(input);
  return fetch(url).then(function (response) {
    return response.json();
  }).then(function (result) {
    return result;
  });
}

function locationToGeometryBounds(location) {
  if (!location) {
    return Promise.reject();
  }

  var url = "".concat(REACT_APP_API_URL, "/boundsof/").concat(location);
  return fetch(url).then(function (response) {
    return response.json();
  }).then(function (result) {
    return result;
  });
}

var geolocationAvailable = !!navigator.geolocation;
exports.geolocationAvailable = geolocationAvailable;

function getCurrentPosition(success, error) {
  if (!geolocationAvailable) {
    error(new Error('navigator.geolocation unavailable'));
    return;
  }

  success = success || function () {};

  error = error || function () {};

  navigator.geolocation.getCurrentPosition(success, error);
}