"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactHelmet = _interopRequireDefault(require("react-helmet"));

var _react = _interopRequireDefault(require("react"));

var _politiqueCookies = _interopRequireDefault(require("@cecaz-immo/ui/dist/components/legal/politique-cookies"));

var _Page = _interopRequireDefault(require("../components/page/Page"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _process$env = process.env,
    REACT_APP_EMAIL_PUBLIC = _process$env.REACT_APP_EMAIL_PUBLIC,
    REACT_APP_URL = _process$env.REACT_APP_URL,
    REACT_APP_WWW = _process$env.REACT_APP_WWW;

var _default = function _default() {
  return _react.default.createElement(_Page.default, {
    className: "PageCookies"
  }, _react.default.createElement(_reactHelmet.default, null, _react.default.createElement("title", null, REACT_APP_WWW, " \u2013 Politique de cookies")), _react.default.createElement("section", {
    className: "page-center section last-section"
  }, _react.default.createElement(_politiqueCookies.default, {
    appLegalEmail: REACT_APP_EMAIL_PUBLIC,
    appUrl: REACT_APP_URL,
    appWWW: REACT_APP_WWW
  })));
};

exports.default = _default;