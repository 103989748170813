"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeFile = exports.uploadFile = exports.sendFile = void 0;

var _s = _interopRequireDefault(require("aws-sdk/clients/s3"));

require("./aws");

var _session = require("./session");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _process$env = process.env,
    REACT_APP_API_URL = _process$env.REACT_APP_API_URL,
    REACT_APP_AWS_S3_BUCKET_NAME = _process$env.REACT_APP_AWS_S3_BUCKET_NAME;
var s3 = new _s.default({
  apiVersion: '2006-03-01',
  params: {
    Bucket: REACT_APP_AWS_S3_BUCKET_NAME
  }
});

var getUploaderUrl = function getUploaderUrl(key) {
  return (0, _session.fetchWithSession)("".concat(REACT_APP_API_URL, "/promoteur/upload/file"), {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({
      key: key
    })
  }).then(function (response) {
    if (!response.ok) {
      return Promise.reject(response.json());
    }

    return response.json();
  });
};

var sendFile = function sendFile(url, blob) {
  return fetch(url, {
    headers: {
      'Content-Type': blob.type
    },
    method: 'PUT',
    body: blob
  }).then(function (response) {
    if (!response.ok) {
      return Promise.reject(response);
    }

    return response;
  });
};

exports.sendFile = sendFile;

var uploadFile = function uploadFile(file) {
  var filename = file.name;
  var key = filename; //`${filename}-${order}`;

  return getUploaderUrl(key).then(function (result) {
    return sendFile(result.url, file);
  });
};

exports.uploadFile = uploadFile;

var removeFile = function removeFile(key) {
  return new Promise(function (resolve, reject) {
    s3.deleteObject({
      Key: key
    }, function (err, data) {
      if (err) {
        reject(err);
        return;
      }

      resolve(data);
    });
  });
};

exports.removeFile = removeFile;