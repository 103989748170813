import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ROLE_SUPER_ADMIN } from '@cecaz-immo/app-developer/dist/lib/roles';
import { WithRole } from '@cecaz-immo/app-developer/dist/components/security/security';

import { requestDeleteEditDeveloperAction } from '../../../../store/actions/edit-developer';

import UserListItem from '../../../user/list/item/item';
import UserListItemField from '../../../user/list/item/field/field';

import './item.scss';

class DeveloperEditListItem extends React.Component {
	constructor(props) {
		super(props);

		this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
	}

	onDeleteConfirm() {
		const { deleteDeveloper, developer } = this.props;
		deleteDeveloper(developer);
	}

	render() {
		const {
			developer,
			className,
			lp,
			...props
		} = this.props;
		return (
			<>
				<UserListItem
					className={`DeveloperEditListItem ${className}`}
					confirmDeleteMessage={(
						<>
							Vous êtes sur le point de <b>supprimer un {lp ? 'partenaire' : 'promoteur'}</b>.
							Cette action est <b>irréversible</b>.<br />
							<br />
							Voulez-vous continuer?
						</>
					)}
					editLink={`/developer/edit/${developer.id}`}
					onDeleteConfirm={this.onDeleteConfirm}
					{...props}
				>
					<div className="info">
						<UserListItemField label="Nom commercial" value={developer.tradingName} />
						<UserListItemField label="Nom - prénom" value={`${developer.lastName.toUpperCase()} ${developer.firstName}`} />
						<UserListItemField label="Téléphone" value={developer.phone} />
						<UserListItemField label="Email" value={developer.email} />
					</div>
					<WithRole role={ROLE_SUPER_ADMIN}>
						{
							developer.admin
							&& (
								<div className="info-admin">
									<UserListItemField
										label="Admininistrateur"
										value={`${developer.admin.lastName.toUpperCase()} ${developer.admin.firstName}`}
									/>
								</div>
							)
						}
					</WithRole>
				</UserListItem>
			</>
		);
	}
}


DeveloperEditListItem.propTypes = {
	className: PropTypes.string,
	developer: PropTypes.shape({ id: PropTypes.number }).isRequired,
	deleteDeveloper: PropTypes.func.isRequired,
	lp: PropTypes.bool,
};

DeveloperEditListItem.defaultProps = {
	className: '',
	lp: false,
};

const mapDispatchToProps = dispatch => ({
	deleteDeveloper: developer => dispatch(requestDeleteEditDeveloperAction(developer)),
});

const DeveloperEditListItemContainer = connect(
	null,
	mapDispatchToProps,
)(DeveloperEditListItem);

export default DeveloperEditListItemContainer;
