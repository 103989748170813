import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { PUBLISHED, TO_CORRECT, WAITING } from '@cecaz-immo/app-developer/dist/lib/status';
import PageContent from '@cecaz-immo/ui/dist/components/page/content';
import ProgrammeDescription from '@cecaz-immo/app-client/dist/components/programme/programme-description';
import ProgrammeValidation from '@cecaz-immo/ui/dist/components/programme/validation';

import {
	updateProgrammeStatus as updateProgrammeStatusAction,
	getCurrentProgrammeToEdit,
} from '@cecaz-immo/app-developer/dist/store/actions/edit-programme';

import programmeValidationSchema from '../../schemas/programme-validation';
import './programme.scss';

class Programme extends React.Component {
	constructor(props) {
		super(props);

		const { loadProgramme } = props;

		this.onChangeCommentary = this.onChangeCommentary.bind(this);
		this.onRejectProgramme = this.onRejectProgramme.bind(this);
		this.onAcceptProgramme = this.onAcceptProgramme.bind(this);
		this.onSubmit = this.onSubmit.bind(this);

		this.state = {
			commentary: '',
			displayWarningMessage: false,
			loading: false,
			status: null,
		};
		loadProgramme();
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			commentary: nextProps.programme.commentary,
		});
	}

	onChangeCommentary(e) {
		this.setState({
			commentary: e.currentTarget.value,
		});
	}

	onSubmit(e) {
		e.preventDefault();
		this.setState({ loading: true });
		const { history, updateProgrammeStatus } = this.props;
		const { commentary, status } = this.state;
		if (!status) return false;
		return updateProgrammeStatus(status, commentary)
			.then(() => history.push('/'))
			.catch((error) => {
				console.error(error);
				this.setState({ loading: false, status: null });
			});
	}

	onAcceptProgramme() {
		this.setState({ status: PUBLISHED });
		return true;
	}

	onRejectProgramme(e) {
		const { commentary } = this.state;

		if (!commentary) {
			e.preventDefault();
			this.setState({
				displayWarningMessage: true,
			});
			return false;
		}
		this.setState({ status: TO_CORRECT });
		return true;
	}

	render() {
		const { programme } = this.props;
		const {
			commentary,
			displayWarningMessage,
			loading,
		} = this.state;

		return (
			<PageContent className="PageProgramme">
				{
					programme.status === WAITING && (
						<ProgrammeValidation
							commentary={commentary}
							displayWarningMessage={displayWarningMessage}
							loading={loading}
							programmeValidationSchema={programmeValidationSchema}
							onAcceptProgramme={this.onAcceptProgramme}
							onRejectProgramme={this.onRejectProgramme}
							onSubmit={this.onSubmit}
							onChangeCommentary={this.onChangeCommentary}
						/>
					)
				}
				<ProgrammeDescription programme={programme} displayMode />
			</PageContent>
		);
	}
}

Programme.propTypes = {
	history: PropTypes.shape({ push: PropTypes.func }),
	loadProgramme: PropTypes.func.isRequired,
	programme: PropTypes.shape({ commentary: PropTypes.string }),
	updateProgrammeStatus: PropTypes.func.isRequired,
};

Programme.defaultProps = {
	history: {},
	programme: {},
};

const mapStateToProps = state => ({
	programme: state.editProgramme,
	otherProgrammes: state.programme.otherProgrammes,
});

const mapDispatchToProps = (dispatch, ownProps) => {
	const { uuid } = ownProps.match.params;
	return {
		loadProgramme: () => {
			if (!uuid) {
				return Promise.reject();
			}
			return dispatch(getCurrentProgrammeToEdit(uuid));
		},
		updateProgrammeStatus: (status, commentary) => (
			dispatch(updateProgrammeStatusAction(status, commentary))
		),
	};
};

const ProgrammeContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Programme);

export default ProgrammeContainer;
